import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Container, Header, Content, Sidebar as RSidebar, Loader, useToaster } from 'rsuite';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import Hotels from './pages/hotels/Hotels';
import Tours from './pages/tours/Tours';
import Dashboard from './pages/main/Dashboard';
import AppNavbar from './components/AppNavbar';
import Sidebar from './components/Sidebar';
import './App.scss'
import 'rsuite/dist/rsuite.min.css';
import { connect } from 'react-redux';
import Login from './pages/login/Login';
import Categories from './pages/category/Categories';
import SubCategories from './pages/subCategory/SubCategories';
import Transfers from './pages/transfers/Transfers';
import Education from './pages/education/Education';
import Agencies from './pages/agency/Agencies';
import PrivateRoutes from './conf/PrivateRoutes';
import { CustomToaster, SwalConfirm, SwalDeleteConfirm, SwalInfoPopap } from './components/CustomComponents';
import { commonErrorAction, commonSuccessAction, commonValidationErrorAction, logoutAction, setPopapShownAction } from './pages/redux/actions';
import Staff from './pages/staff/Staff';
import Users from './pages/users/Users';
import Roles from './pages/role/Roles';
import AgencyProfile from './pages/agencyProfile/AgencyProfile';
import Banners from './pages/banners/Banners';
import Facilities from './pages/definitions/facilities/Facilities';
import Permissions from './pages/definitions/permissions/Permissions';
import RoomType from './pages/definitions/roomType/RoomType';
import Policies from './pages/definitions/policies/Policies';

const privateRoutes = [
  {
    path: '/listing/category',
    element: <Categories />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/admin/users',
    element: <Users />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/admin/agencies',
    element: <Agencies />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/admin/banners',
    element: <Banners />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },

  {
    path: '/agency/staff',
    element: <Staff />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/agency/roles',
    element: <Roles />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },


  {
    path: '/listing/tours',
    element: <Tours />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/listing/hotels',
    element: <Hotels />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/listing/transfers',
    element: <Transfers />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/listing/education',
    element: <Education />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/listing/sub-category',
    element: <SubCategories />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/agency-profile',
    element: <AgencyProfile />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/definitions/facilities',
    element: <Facilities />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/definitions/permissions',
    element: <Permissions />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/definitions/room-types',
    element: <RoomType />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
  {
    path: '/definitions/policies',
    element: <Policies />,
    isDisabled: false,
    exact: true,
    isAuthNeeded: true,
    isPrivate: true
  },
]

function App(props) {

  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message.startsWith('ResizeObserver loop')) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    if (props.isLogged && !props.popapShown) {
      SwalConfirm({
        title: `<h1><strong>Her türlü haram içerik yasaktır.</strong></h1>`,
        html: `
          <ul style="color: rgb(248, 95, 95); font-size: 18px">
            <li>Çıplak kadın veya erkek fotoğrafları yasaktır.</li>
            <li>Alkol veya haram yiyecek içeren turlar, oteller veya diğer içerikler yasaktır.</li>
          </ul>
          <h5><b>Yasaklanan içerik silinecektir ve moderatörler tarafından engellenecektir!</b></h5>
        `,
        confirmButtonText: `<i class="fa fa-thumbs-up"></i> Kabul Ediyorum`,
        cancelButtonText: `<i class="fa fa-thumbs-down"></i> Kabul Etmiyorum!`,
        backdrop: `rgba(253, 47, 58, 0.4)`,
        focusConfirm: false,
        onConfirm: (e) => {
          props.setPopapShownAction(true);
        },
        onCancel: (e) => {}
      })
    }
  }, [props.isLogged, props.popapShown])


  useEffect(() => {
    if (props.common_error) {
      SwalInfoPopap({
        position: "center", icon: "error",
        title: props.common_error?.message,
        text: `Hata kodu: ${props.common_error?.status}`,
        thenFunc: (result) => {
          if (props.common_error?.status === 401) {
            props.logoutAction();
          }
          props.commonErrorAction(null);
        }
      })
    }
  }, [props.common_error])

  useEffect(() => {
    if (props.common_success) {
      SwalInfoPopap({
        position: "top-end", icon: "success",
        title: props.common_success?.message,
        text: "Değişiklikleriniz kaydedildi",
        thenFunc: (result) => {
          props.commonSuccessAction(null);
        }
      })
    }
  }, [props.common_success])

  useEffect(() => {
    if (props.common_validation_error) {
      // Create the list of error messages
      let errors = props.common_validation_error?.errors?.map(err =>
        Object.entries(err).map(([key, value]) => `
      <li style="text-align: left; list-style-type: none; margin-bottom: 5px;">
        <b style='color: red;'>${key}</b>: <strong>${value}</strong>
      </li>
    `)
      );

      // Join the errors and wrap them in <ul> or <ol> for list format
      let errorList = `<ul style='padding-left: 20px; text-align: left;'>${errors?.flat()?.join('') ?? ""}</ul>`;

      SwalInfoPopap({
        position: "center",
        icon: "error",
        timer: 10000,
        title: `Hata kodu: ${props.common_validation_error.status}`,
        html: errorList,
        thenFunc: (result) => {
          props.commonValidationErrorAction(null);
        }
      })
    }
  }, [props.common_validation_error])


  return (
    <Container style={{ position: 'relative', height: '100vh' }}>
      <Suspense fallback={<Loader backdrop content="Loading..." vertical />}>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route
              path="*"
              element={
                <>
                  <AppNavbar />
                  <Container style={{ position: 'relative' }}>
                    <Sidebar expanded={expanded} setExpanded={setExpanded} />
                    <Container className='content' style={{ marginLeft: expanded ? "270px" : "90px" }}>
                      <Routes>
                        {privateRoutes.filter(item => item.isAuthNeeded).map((val, key) => (
                          <Route key={key} exact path={val?.path} element={val?.element} />
                        ))}
                      </Routes>
                    </Container>
                  </Container>
                </>
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </Container>
  );
}
const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
  common_error: state.commonReducer.common_error,
  common_success: state.commonReducer.common_success,
  common_validation_error: state.commonReducer.common_validation_error,
  isLogged: state.commonReducer.isLogged,
  popapShown: state.commonReducer.popapShown
});

const mapDispatchToProps = dispatch => ({
  commonErrorAction: (data) => dispatch(commonErrorAction(data)),
  commonSuccessAction: (data) => dispatch(commonSuccessAction(data)),
  commonValidationErrorAction: (data) => dispatch(commonValidationErrorAction(data)),
  setPopapShownAction: (data) => dispatch(setPopapShownAction(data)),
  logoutAction: () => dispatch(logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
