import React, { useCallback, useEffect, useState } from 'react';
import { Sidenav, Nav, Toggle } from 'rsuite';
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import GroupIcon from '@rsuite/icons/legacy/Group';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PeoplesCostomizeIcon from '@rsuite/icons/PeoplesCostomize';
import DragableIcon from '@rsuite/icons/Dragable';
import CharacterAuthorizeIcon from '@rsuite/icons/CharacterAuthorize';
import TreeIcon from '@rsuite/icons/Tree';
import SingleSourceIcon from '@rsuite/icons/SingleSource';
import UserBadgeIcon from '@rsuite/icons/UserBadge';
import AdminIcon from '@rsuite/icons/Admin';
import ListIcon from '@rsuite/icons/List';
import PeoplesIcon from '@rsuite/icons/Peoples';
import PeopleBranchIcon from '@rsuite/icons/PeopleBranch';
import CharacterLockIcon from '@rsuite/icons/CharacterLock';
import ImageIcon from '@rsuite/icons/Image';
import { connect } from 'react-redux';
import { hasPermission } from '../utils/helpers';
import { RiListIndefinite } from "react-icons/ri";
import { TbListDetails } from "react-icons/tb";
import { FaLock } from "react-icons/fa";
import { MdRoomPreferences } from "react-icons/md";
import { MdPolicy } from "react-icons/md";



const Sidebar = (props) => {

  const { expanded, setExpanded } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const [activeKey, setActiveKey] = useState('/');
  const barSelectHandler = useCallback((value) => {
    setActiveKey(value);
    navigate(value);
  }, []);

  useEffect(() => {
    setActiveKey(location.pathname);
  }, [location.pathname])

  const listingBar = [
    {
      title: "Kategoriler",
      location: '/listing/category',
      icon: <TreeIcon />,
      hasPermission: hasPermission(props.user?.role?.permissions, "category view")
    },
    {
      title: "Alt Kategoriler",
      location: '/listing/sub-category',
      icon: <TreeIcon />,
      hasPermission: hasPermission(props.user?.role?.permissions, "sub category view")
    },
    {
      title: "Turlar",
      location: '/listing/tours',
      icon: <GroupIcon />,
      hasPermission: hasPermission(props.user?.role?.permissions, "tour view")
    },
    {
      title: "Oteller",
      location: '/listing/hotels',
      icon: <CharacterAuthorizeIcon />,
      hasPermission: hasPermission(props.user?.role?.permissions, "hotel view")
    },
    {
      title: "Transferler",
      location: '/listing/transfers',
      icon: <DragableIcon />,
      hasPermission: hasPermission(props.user?.role?.permissions, "transfer view")
    },
    {
      title: "Eğitim",
      location: '/listing/education',
      icon: <PeoplesCostomizeIcon />,
      hasPermission: hasPermission(props.user?.role?.permissions, "education view")
    },
  ];

  const adminBar = [
    {
      title: "Agentalar",
      location: '/admin/agencies',
      icon: <SingleSourceIcon />,
      hasPermission: hasPermission(props.user?.role?.permissions, "agency view")
    },
    {
      title: "Kullanıcılar",
      location: '/admin/users',
      icon: <PeoplesIcon />,
      hasPermission: hasPermission(props.user?.role?.permissions, "user view")
    },
    {
      title: "Afişler",
      location: '/admin/banners',
      icon: <ImageIcon />,
      hasPermission: hasPermission(props.user?.role?.permissions, "banner view")
    },
  ];

  const agencyBar = [
    {
      title: "Agenta Üyeleri",
      location: '/agency/staff',
      icon: <PeopleBranchIcon />,
      hasPermission: hasPermission(props.user?.role?.permissions, "staff view")
    },
    {
      title: "Roller ve Izinler",
      location: '/agency/roles',
      icon: <CharacterLockIcon />,
      hasPermission: hasPermission(props.user?.role?.permissions, "role view")
    },
  ];

  const definitionsBar = [
    {
      title: "Olanaklar",
      location: '/definitions/facilities',
      icon: <TbListDetails className='rs-icon' />,
      hasPermission: hasPermission(props.user?.role?.permissions, "facility view")
    },
    {
      title: "İzinler",
      location: '/definitions/permissions',
      icon: <FaLock className='rs-icon' />,
      hasPermission: hasPermission(props.user?.role?.permissions, "permission view")
    },
    {
      title: "Oda Tipleri",
      location: '/definitions/room-types',
      icon: <MdRoomPreferences className='rs-icon' />,
      hasPermission: hasPermission(props.user?.role?.permissions, "room type view")
    },
    {
      title: "Politikalar",
      location: '/definitions/policies',
      icon: <MdPolicy className='rs-icon' />,
      hasPermission: hasPermission(props.user?.role?.permissions, "policy view")
    },
  ];

  return (
    <div className='side-bar'>
      <Sidenav expanded={expanded}>
        <Sidenav.Body>
          <Nav activeKey={activeKey} onSelect={barSelectHandler}>
            <Nav.Item eventKey="/agency-profile" active={location.pathname === "/agency-profile"} icon={<UserBadgeIcon />}>Agenta Profil</Nav.Item>

            {/* list of data */}
            {(hasPermission(props.user?.role?.permissions, "category view") || hasPermission(props.user?.role?.permissions, "sub category view") ||
              hasPermission(props.user?.role?.permissions, "tour view") ||
              hasPermission(props.user?.role?.permissions, "transfer view") ||
              hasPermission(props.user?.role?.permissions, "hotel view") || hasPermission(props.user?.role?.permissions, "education view")) &&
              (
                <Nav.Menu
                  placement="rightStart"
                  eventKey="/listing"
                  title="Listeler"
                  icon={<ListIcon />}
                >
                  {listingBar?.filter(el => el.hasPermission).map((item, key) => (
                    <Nav.Item style={{ display: 'flex' }} active={item.location === location.pathname} key={key} eventKey={item.location} icon={item.icon}>{item.title}</Nav.Item>
                  ))}
                </Nav.Menu>
              )}
            {/* definitions */}
            {(hasPermission(props.user?.role?.permissions, "facility view") || hasPermission(props.user?.role?.permissions, "permission view") ||
              hasPermission(props.user?.role?.permissions, "room type view") || hasPermission(props.user?.role?.permissions, "policy view")) &&
              (
                <Nav.Menu
                  placement="rightStart"
                  eventKey="/definitions"
                  title="Tanımlar"
                  icon={<RiListIndefinite className='rs-icon' />}
                >
                  {definitionsBar?.filter(el => el.hasPermission).map((item, key) => (
                    <Nav.Item style={{ display: 'flex' }} active={item.location === location.pathname} key={key} eventKey={item.location} icon={item.icon}>{item.title}</Nav.Item>
                  ))}
                </Nav.Menu>
              )}
            {/* admin panel */}
            {(hasPermission(props.user?.role?.permissions, "agency view") || hasPermission(props.user?.role?.permissions, "user view")) && (
              <Nav.Menu
                placement="rightStart"
                eventKey="/admin"
                title="Admin"
                icon={<AdminIcon />}
              >
                {adminBar?.filter(el => el.hasPermission).map((item, key) => (
                  <Nav.Item style={{ display: 'flex' }} active={item.location === location.pathname} key={key} eventKey={item.location} icon={item.icon}>{item.title}</Nav.Item>
                ))}
              </Nav.Menu>
            )}

            {/* agency panel */}
            {(hasPermission(props.user?.role?.permissions, "staff view") || hasPermission(props.user?.role?.permissions, "role view")) && (
              <Nav.Menu
                placement="rightStart"
                eventKey="/agency"
                title="Agenta"
                icon={<AdminIcon />}
              >
                {agencyBar?.filter(el => el.hasPermission).map((item, key) => (
                  <Nav.Item style={{ display: 'flex' }} active={item.location === location.pathname} key={key} eventKey={item.location} icon={item.icon}>{item.title}</Nav.Item>
                ))}
              </Nav.Menu>
            )}
          </Nav>
        </Sidenav.Body>
        <Sidenav.Toggle onToggle={expanded => setExpanded(expanded)} />
      </Sidenav>
    </div>
  )
}

const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
  user: state.commonReducer.user,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
