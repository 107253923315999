import { DELETE_BANNER, CREATE_BANNER, UPDATE_BANNER, GET_BANNERS, BANNERS_DATA, UPLOAD_IMAGES_BANNER, DELETE_IMAGE_BANNER } from "./constants";

export const saveBannerAction = (body, callback) => ({
    type: CREATE_BANNER,
    body,
    callback
});

export const updateBannerAction = (id, body, callback) => ({
    type: UPDATE_BANNER,
    id,
    body,
    callback
});

export const deleteBannerAction = (id, callback) => ({
    type: DELETE_BANNER,
    id,
    callback
});
export const uploadImagesBannerAction = (id, body, callback) => ({
    type: UPLOAD_IMAGES_BANNER,
    id,
    body,
    callback
});

export const deleteImageBannerAction = (id, body, callback) => ({
    type: DELETE_IMAGE_BANNER,
    id,
    body,
    callback
});


export const getBannersAction = (page, limit) => ({
    type: GET_BANNERS,
    page,
    limit
});

export const bannersDataAction = (data) => ({
    type: BANNERS_DATA,
    data
});
