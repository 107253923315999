import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Accordion, Button, ButtonGroup, ButtonToolbar, CheckPicker, Col, Form, Grid, IconButton, Input, Modal, Row, SelectPicker, Stack, Tooltip, Whisper } from 'rsuite';
import { CustomInput, DynamicallyTag } from '../../components/CustomComponents';
import { connect } from 'react-redux';
import './tour.scss';
import { saveTourAction, toursDataAction, updateTourAction } from './redux/actions';
import { getCategoriesAction } from '../category/redux/actions';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import TourPrograms from './form/TourPrograms';
import { MdDomain } from "react-icons/md";
import { BsFillInfoSquareFill } from "react-icons/bs";
import { MdOutlineSpeakerNotes } from "react-icons/md";
import { GiDetour } from "react-icons/gi";
import { BsCalendarDateFill } from "react-icons/bs";
import TourDatesPrices from './form/TourDatesPrices';
import { FaAngleDoubleUp } from "react-icons/fa";
import { FaAngleDoubleDown } from "react-icons/fa";

const Header = props => {
    const { Icon, title, subtitle, ...rest } = props;
    return (
        <Stack {...rest} spacing={10} alignItems="flex-end" justifyContent='flex-start'>
            {Icon}
            <Stack spacing={2} direction="column" alignItems="flex-start">
                <div>{title}</div>
                <div style={{ color: 'var(--rs-text-secondary)', fontSize: 12 }}>{subtitle}</div>
            </Stack>
        </Stack>
    );
};


const TourCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;
    const {
        handleSubmit,
        control, reset, watch, setValue, getValues,
        formState: { errors }
    } = useForm();

    const [categoryItems, setCategoryItems] = useState(props.categories_data?.data?.map(item => ({ label: item.title, value: item.id, subCategories: item?.subCategories ?? [] })) ?? []);
    const categoryUpdateData = () => {
        if (categoryItems.length === 0) {
            setCategoryItems(props.categories_data?.data?.map(item => ({ label: item.title, value: item.id, subCategories: item?.subCategories ?? [] })) ?? []);
        }
    };
    const categoryId = watch('category_id');

    const [subCategoryItems, setSubCategoryItems] = useState([]);

    const subCategoryUpdateData = (id) => {
        if (categoryItems.length > 0) {
            let subCategories = categoryItems?.find(el => el.value === parseInt(id))?.subCategories?.map(item => ({ label: item.title, value: item.id })) ?? []
            if (subCategories?.length > 0) {
                setSubCategoryItems(subCategories);
            }
        }
    };
    const [programs, setPrograms] = useState([]);
    const [dateAndPrices, setDatesAndPrices] = useState([]);

    useEffect(() => {
        if (!Boolean(props.categories_data?.subCategories)) {
            props.getCategoriesAction(1, 50);
        }
    }, [])

    useEffect(() => {
        if (editData) {
            Object.entries(editData).forEach(([key, value]) => {
                if ((key === "cities_to_visit" || key === "countries_to_visit"
                    || key === "services_inc_in_price" || key === "search_value"
                    || key === "services_not_inc_in_price" || key === "notes"
                )) {
                    setValue(key, Boolean(value) ? value.split("->") : []);
                } else if (key === "tourDates" && value?.length > 0) {
                    setDatesAndPrices(value.map(tourDate => {
                        let tempTourDate = { id: tourDate.id }
                        Object.entries(tourDate).forEach(([key, val], index) => {
                            if ((key === "start_date" || key === "end_date") && val) {
                                tempTourDate = { ...tempTourDate, [key]: new Date(val) };
                            } else if (key === "datePrices" && val?.length > 0) {
                                tempTourDate = { ...tempTourDate, date_prices: [...val] }
                            }
                        })
                        return tempTourDate;
                    }));
                } else if (key === "subCategories") {
                    setValue("sub_categories", value?.map(el => el.id) ?? []);
                } else if (key === "program" && value) {
                    let parsedProgram = JSON.parse(value);
                    setPrograms(parsedProgram);
                } else if ((key === "title" || key === "sub_title" || key === "important_notes" ||
                    key === "days" || key === "nights" || key === "isHighlighted"
                ) && value) {
                    setValue(key, value);
                }
            })
            if (editData?.category_id) {
                subCategoryUpdateData(editData.category_id);
                setValue("category_id", parseInt(editData?.category_id));
            }
        }
    }, [editData])

    const onSubmit = data => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value], index) => {
            if (key === "cities_to_visit" || key === "countries_to_visit"
                || key === "services_inc_in_price" || key === "search_value"
                || key === "services_not_inc_in_price" || key === "notes") {
                formData.append(key, value?.length > 0 ? value.join("->") : "");
            } else if (key === "sub_categories") {
                value?.forEach(item => {
                    formData.append(`${key}[]`, parseInt(item));
                })
            } else {
                formData.append(key, value);
            }
        })

        const tempDateAndPrices = dateAndPrices.map((value, index) => {
            if (value?.date_prices?.length > 0) {
                value.date_prices = value.date_prices.map(prices => {
                    let tempPrices = {};
                    Object.entries(prices).map(([key, val], _) => {
                        if (key === "currency") {
                            tempPrices = { ...tempPrices, [key]: Boolean(val) ? val : "TRY" }
                        } else if (key === "hotels_type") {
                            tempPrices = { ...tempPrices, [key]: val }
                        } else {
                            tempPrices = { ...tempPrices, [key]: Boolean(val) ? parseFloat(val) : 0 }
                        }
                    })
                    return tempPrices;
                })
            }
            return value;
        })

        formData.append('tour_dates', JSON.stringify(tempDateAndPrices))
        formData.append("program", JSON.stringify(programs));


        if (editData) {
            props.updateTourAction(editData.id, formData, callback)
        } else {
            props.saveTourAction(formData, callback);
        }
    };
    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.tours_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.tours_data.data, data];
            }
            props.toursDataAction({ ...props.tours_data, data: updatedTableList, total: props.tours_data.total + 1 });
            resetFields();
        }
    }, [])

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset();
        setPrograms([])
        setOpen(prev => ({ ...prev, [type]: false }))
    }

    return (
        <Modal backdrop={"static"} size={"lg"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Tur Ekle</Modal.Title>
            </Modal.Header>
            <Form fluid onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid fluid className='tour_form'>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Accordion bordered>
                                <Accordion.Panel header={<Header Icon={<BsFillInfoSquareFill />} title={"Genel Bilgiler"} />} defaultExpanded onClick={(event) => event.preventDefault()}>
                                    <Row>
                                        {/* category_id */}
                                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                            <Controller
                                                name="category_id"
                                                control={control}
                                                defaultValue={''}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Kategori"}
                                                        required
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <SelectPicker
                                                                data={categoryItems}
                                                                value={field?.value}
                                                                onChange={field?.onChange}
                                                                onSearch={categoryUpdateData}
                                                                onOpen={categoryUpdateData}
                                                                style={{ width: 300 }}
                                                            />
                                                        }
                                                    />
                                                )}
                                                rules={{ required: "Gerekli" }}
                                            />
                                        </Col>
                                        {/* sub_categories */}
                                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                            <Controller
                                                name="sub_categories"
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Alt Kategori"}
                                                        error={errors[field?.name]?.message}
                                                        required
                                                        children={
                                                            <CheckPicker
                                                                data={subCategoryItems}
                                                                value={field?.value}
                                                                onChange={field?.onChange}
                                                                onSearch={() => subCategoryUpdateData(categoryId)}
                                                                onOpen={() => subCategoryUpdateData(categoryId)}
                                                                style={{ width: 300 }}
                                                            />
                                                        }
                                                    />
                                                )}
                                                rules={{ required: "Gerekli" }}
                                            />
                                        </Col>
                                        {/* days */}
                                        <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                                            <Controller
                                                name="days"
                                                control={control}
                                                defaultValue={0}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Gün"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <Input type='number' value={field?.value} onChange={field?.onChange} />
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        {/* nights */}
                                        <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                                            <Controller
                                                name="nights"
                                                control={control}
                                                defaultValue={0}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Gece"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <Input type='number' value={field?.value} onChange={field?.onChange} />
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        {/* title */}
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                                            <Controller
                                                name="title"
                                                control={control}
                                                defaultValue={''}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Başlık"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                                <Input value={field?.value} onChange={field?.onChange} />
                                                            </Whisper>
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        {/* sub_title */}
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                                            <Controller
                                                name="sub_title"
                                                control={control}
                                                defaultValue={''}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Alt Başlık"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                                <Input value={field?.value} onChange={field?.onChange} />
                                                            </Whisper>
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Accordion.Panel>
                                <Accordion.Panel header={<Header Icon={<MdDomain />} title={"Gezilecek yerler"} />} onClick={(event) => event.preventDefault()}>
                                    <Row>
                                        {/* countries_to_visit */}
                                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                                            <Controller
                                                name="countries_to_visit"
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Gezilecek Ülkeler"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <div className='dynamically_tag_wrapper'>
                                                                <DynamicallyTag tags={field?.value} setTags={field?.onChange} />
                                                            </div>
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        {/* cities_to_visit */}
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Controller
                                                name="cities_to_visit"
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Gezilecek Şehirler"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <div className='dynamically_tag_wrapper'>
                                                                <DynamicallyTag tags={field?.value} setTags={field?.onChange} />
                                                            </div>
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Accordion.Panel>
                                <Accordion.Panel header={<Header Icon={<MdOutlineSpeakerNotes />} title={"Notlar ve Hizmetler"} />} onClick={(event) => event.preventDefault()}>
                                    <Row>
                                        {/* important_notes */}
                                        <Col xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
                                            <Controller
                                                name="important_notes"
                                                control={control}
                                                defaultValue={''}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Önemli Not"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <Input value={field?.value} onChange={field?.onChange} />
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        {/* isHighlighted */}
                                        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                                            <Controller
                                                name="isHighlighted"
                                                control={control}
                                                defaultValue={0}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Öne Çıkanlardan?"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <ButtonToolbar>
                                                                <ButtonGroup>
                                                                    <Button style={{ background: field.value === 1 ? '#2c792f' : "#c5c5c5" }} onClick={() => field?.onChange(1)} appearance={"primary"} endIcon={<FaAngleDoubleUp />}>Evet</Button>
                                                                    <Button style={{ background: field.value === 0 ? '#eb3e3e' : "#c5c5c5" }} onClick={() => field?.onChange(0)} appearance={"primary"} endIcon={<FaAngleDoubleDown />}>Hayır</Button>
                                                                </ButtonGroup>
                                                            </ButtonToolbar>
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        {/* search_value */}
                                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Controller
                                                name="search_value"
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Aranacak Kelimeler"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <div className='dynamically_tag_wrapper'>
                                                                <DynamicallyTag tags={field?.value} setTags={field?.onChange} />
                                                            </div>
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        {/* notes */}
                                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Controller
                                                name="notes"
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Notlar"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <div className='dynamically_tag_wrapper'>
                                                                <DynamicallyTag inputWidth={200} tags={field?.value} setTags={field?.onChange} />
                                                            </div>
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        {/* services_inc_in_price */}
                                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                                            <Controller
                                                name="services_inc_in_price"
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Fiyat Dahil Olan Hizmetler"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <div className='dynamically_tag_wrapper'>
                                                                <DynamicallyTag tags={field?.value} setTags={field?.onChange} />
                                                            </div>
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        {/* services_not_inc_in_price */}
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Controller
                                                name="services_not_inc_in_price"
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Fiyat Dahil Olmayan Hizmetler"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <div className='dynamically_tag_wrapper'>
                                                                <DynamicallyTag tags={field?.value} setTags={field?.onChange} />
                                                            </div>
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Accordion.Panel>
                                <Accordion.Panel header={<Header Icon={<GiDetour />} title={"Program"} />} onClick={(event) => event.preventDefault()}>
                                    <TourPrograms setPrograms={setPrograms} programs={programs} />
                                </Accordion.Panel>
                                <Accordion.Panel header={<Header Icon={<BsCalendarDateFill />} title={"Tur Tarihleri"} />} onClick={(event) => event.preventDefault()}>
                                    <TourDatesPrices setDatesAndPrices={setDatesAndPrices} dateAndPrices={dateAndPrices} />
                                </Accordion.Panel>
                            </Accordion>
                        </Col>



                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary" loading={props.common_requesting}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal >
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    tours_data: state.tourReducer.tours_data,
    categories_data: state.categoryReducer.categories_data,
});

const mapDispatchToProps = dispatch => ({
    getCategoriesAction: (page, limit) => dispatch(getCategoriesAction(page, limit)),
    toursDataAction: (data) => dispatch(toursDataAction(data)),
    saveTourAction: (body, callback) => dispatch(saveTourAction(body, callback)),
    updateTourAction: (id, body, callback) => dispatch(updateTourAction(id, body, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(TourCUModal)