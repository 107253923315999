
import axios from 'axios';


export const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/v1`,
    timeout: 2000000,
    headers: {
        Accept: 'application/json'
    }
});


export const authToken = token => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
};

export const setParams = (params = "", token = "", method = "post") => {
    const config = {
        headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
        },
    };

    if (method === "delete") {
        config.data = params; // Use `data` field for DELETE request body
    } else {
        config.params = params; // Use `params` field for query params in POST requests
    }

    return config;
};
