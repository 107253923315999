
import { call, put, all, takeLatest } from "redux-saga/effects";
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction } from "../../redux/actions";
import { CREATE_HOTEL, DELETE_IMAGE_HOTEL, DELETE_HOTEL, GET_HOTELS, GET_HOTELS_BY_AGENCY, GET_HOTEL_BY_NAME, UPDATE_HOTEL, UPLOAD_IMAGES_HOTEL } from "./constants";
import { createHotel, deleteHotel, getHotelByName, getHotels, getHotelsByAgency, imageDeleteHotel, imagesUploadHotel, updateHotel } from "../../../services/services";
import { hotelsDataAction } from "./actions";


function* uploadImagesHotelSaga({ id, body, callback }) {
    try {
        const response = yield call(imagesUploadHotel, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            debugger
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteImageHotelSaga({ id, body, callback }) {
    try {
        const response = yield call(imageDeleteHotel, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.message);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* createHotelSaga({ body, callback }) {
    try {
        const response = yield call(createHotel, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* updateHotelSaga({ id, body, callback }) {
    try {
        const response = yield call(updateHotel, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteHotelSaga({ id, callback }) {
    try {
        const response = yield call(deleteHotel, id);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(id);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getHotelByNameSaga({ name, callback }) {
    try {
        const response = yield call(getHotelByName, name);
        if (response.status === 200) {
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getHotelsSaga({ page, limit }) {
    try {
        const response = yield call(getHotels, page, limit);
        if (response.status === 200) {
            yield put(hotelsDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getHotelsByAgencySaga({ queryParams }) {
    try {
        const response = yield call(getHotelsByAgency, queryParams);
        if (response.status === 200) {
            yield put(hotelsDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        console.log(error)
        yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(CREATE_HOTEL, createHotelSaga),
    takeLatest(UPDATE_HOTEL, updateHotelSaga),
    takeLatest(DELETE_HOTEL, deleteHotelSaga),
    takeLatest(UPLOAD_IMAGES_HOTEL, uploadImagesHotelSaga),
    takeLatest(DELETE_IMAGE_HOTEL, deleteImageHotelSaga),
    takeLatest(GET_HOTELS, getHotelsSaga),
    takeLatest(GET_HOTELS_BY_AGENCY, getHotelsByAgencySaga),
    takeLatest(GET_HOTEL_BY_NAME, getHotelByNameSaga),
]);