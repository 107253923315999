import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { Button, IconButton, Loader, Pagination, Popover, Row, Table, Whisper, useToaster, } from 'rsuite';
import { connect } from 'react-redux';
import RoomTypeCUModal from './RoomTypeCUModal';
import { roomTypesDataAction, deleteRoomTypeAction, deleteImageRoomTypeAction, uploadImagesRoomTypeAction, updateRoomTypeAction, getRoomTypesAction } from "./redux/actions"
import MoreIcon from '@rsuite/icons/legacy/More';
import { CustomToaster, ImageUploadModal, PermissionsWrapper, SwalConfirm, SwalDeleteConfirm, renderMenu } from '../../../components/CustomComponents';
import { hasPermission } from '../../../utils/helpers';


const ActionCell = ({ rowData, dataKey, callback, ...props }) => {
  return (
    <Table.Cell {...props} className="link-group">
      <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu(rowData, callback, true, true, "room type")}>
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
    </Table.Cell>
  );
};


const RoomTypes = (props) => {

  const [open, setOpen] = useState({ thumbnail: false, images: false, edit: false, create: false });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState(false);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    if (hasPermission(props.user?.role?.permissions, "room type view")) {
      props.getRoomTypesAction(page, limit);
    }
  }, [page, limit])

  const editHandler = (data, type) => {
    if (data) {
      setEditData(data);
      setOpen(prev => ({ ...prev, [type]: true }));
    }
  }

  const deleteHandler = (id) => {
    if (id) {
      SwalDeleteConfirm({
        onConfirm: (e) => {
          props.deleteRoomTypeAction(id, deleteCallback);
        },
        onCancel: (e) => { debugger }
      })
    }
  }

  const deleteCallback = useCallback((id) => {
    if (id) {
      let filteredList = props.roomTypes_data.data?.filter(item => item.id !== id);
      props.roomTypesDataAction({ ...props.roomTypes_data, data: filteredList });
    }
  }, []);

  const actionHandler = useCallback((eventKey, rowData) => {
    switch (eventKey) {
      case 1:
        editHandler(rowData, "images")
        break;
      case 2:
        editHandler(rowData, "thumbnail")
        break;
      case 3:
        editHandler(rowData, "edit")
        break;
      case 4:
        deleteHandler(rowData.id)
        break;
      default:
        break;
    }

  }, [])

  return (
    <PermissionsWrapper permission="room type view">
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
          <h2>Oda Tipleri</h2>
          {hasPermission(props.user?.role?.permissions, "room type create") &&
            <Button appearance="primary" onClick={() => setOpen(prev => ({ ...prev, create: true }))} >
              Tip Oluştur
            </Button>
          }
        </div>
        <div style={{ height: 500, marginTop: '30px' }}>

          <Table data={props.roomTypes_data?.data || []} bordered rowHeight={60} fillHeight>
            <Table.Column width={80} align="center" fixed>
              <Table.HeaderCell>Logo</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <img src={rowData?.thumbnail ?? ""} style={{ width: '40px', height: '40px', objectFit: "cover", borderRadius: '10px' }} />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={200} flexGrow={1} >
              <Table.HeaderCell>Isim</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>

            <Table.Column width={200} flexGrow={1} >
              <Table.HeaderCell>Yatak Tipi</Table.HeaderCell>
              <Table.Cell dataKey="bed_type" />
            </Table.Column>

            <Table.Column width={100} flexGrow={1} >
              <Table.HeaderCell>Boyut</Table.HeaderCell>
              <Table.Cell dataKey="size" />
            </Table.Column>

            <Table.Column width={100} flexGrow={2} >
              <Table.HeaderCell>Maksimum Doluluk</Table.HeaderCell>
              <Table.Cell dataKey="max_occupancy" />
            </Table.Column>

            <Table.Column width={70} fixed>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <ActionCell dataKey="id" callback={actionHandler} />
            </Table.Column>
          </Table>
        </div>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager']}
            total={props.roomTypes_data?.total}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
        {open.create && hasPermission(props.user?.role?.permissions, "room type create") ? <RoomTypeCUModal setOpen={setOpen} open={open.create} type={"create"} /> : null}

        {open.edit && hasPermission(props.user?.role?.permissions, "room type update") ? <RoomTypeCUModal setOpen={setOpen} open={open.edit} editData={editData} type={"edit"} setEditData={setEditData} /> : null}

        {open.thumbnail && hasPermission(props.user?.role?.permissions, "room type update") ? (
          <ImageUploadModal open={open.thumbnail} setOpen={setOpen} type={"thumbnail"} rowData={editData}
            uploadFunc={props.uploadImagesRoomTypeAction} deleteFunc={props.deleteImageRoomTypeAction}
            updateRowDataFunc={props.roomTypesDataAction} tableList={props.roomTypes_data} loading={props.common_requesting}
            setEditData={setEditData}
          />
        ) : null}

        {open.images && hasPermission(props.user?.role?.permissions, "room type update") ? (
          <ImageUploadModal open={open.images} setOpen={setOpen} type={"images"}
            rowData={editData}
            uploadFunc={props.uploadImagesRoomTypeAction}
            deleteFunc={props.deleteImageRoomTypeAction}
            updateRowDataFunc={props.roomTypesDataAction} tableList={props.roomTypes_data}
            loading={props.common_requesting}
            setEditData={setEditData}
          />
        ) : null}

      </div>
    </PermissionsWrapper>
  );
};
const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
  roomTypes_data: state.roomTypeReducer.roomTypes_data,
  common_error: state.commonReducer.common_error,
  user: state.commonReducer.user,
});

const mapDispatchToProps = dispatch => ({
  getRoomTypesAction: (page, limit) => dispatch(getRoomTypesAction(page, limit)),
  deleteRoomTypeAction: (id, callback) => dispatch(deleteRoomTypeAction(id, callback)),
  uploadImagesRoomTypeAction: (id, body, callback) => dispatch(uploadImagesRoomTypeAction(id, body, callback)),
  deleteImageRoomTypeAction: (id, body, callback) => dispatch(deleteImageRoomTypeAction(id, body, callback)),
  roomTypesDataAction: (data) => dispatch(roomTypesDataAction(data)),
  updateRoomTypeAction: (id, body, callback) => dispatch(updateRoomTypeAction(id, body, callback))

});

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypes);
