import {
    SUB_CATEGORIES_DATA,
} from "./constants";

const initialState = {
    sub_categories_data: null,
};

const subCategoryReducer = (state = initialState, action) => {
    switch (action.type) {

        case SUB_CATEGORIES_DATA:
            return {
                ...state,
                sub_categories_data: action.data,
            };
        default:
            return state;
    }
};

export default subCategoryReducer;
