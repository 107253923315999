import { DELETE_PERMISSION, CREATE_PERMISSION, UPDATE_PERMISSION, GET_PERMISSIONS, PERMISSIONS_DATA, } from "./constants";

export const savePermissionAction = (body, callback) => ({
    type: CREATE_PERMISSION,
    body,
    callback
});

export const updatePermissionAction = (id, body, callback) => ({
    type: UPDATE_PERMISSION,
    id,
    body,
    callback
});

export const deletePermissionAction = (id, callback) => ({
    type: DELETE_PERMISSION,
    id,
    callback
});

export const getPermissionsAction = (pagination, page, limit, callback) => ({
    type: GET_PERMISSIONS,
    pagination,
    page,
    limit,
    callback
});


export const permissionsDataAction = (data) => ({
    type: PERMISSIONS_DATA,
    data
});