import {
    PERMISSIONS_DATA,
} from "./constants";

const initialState = {
    permissions_data: null,
};

const permissionReducer = (state = initialState, action) => {
    switch (action.type) {

        case PERMISSIONS_DATA:
            return {
                ...state,
                permissions_data: action.data,
            };
        default:
            return state;
    }
};

export default permissionReducer;
