import React from 'react'
import { Button, Col, IconButton, Input } from 'rsuite';
import { CustomInput } from '../../../components/CustomComponents';
import CloseIcon from '@rsuite/icons/Close';
import '../tour.scss';

const TourPrograms = (props) => {
    const { setPrograms, programs } = props;

    const newProgram = () => {
        setPrograms(prev => [...prev, ({ title: "", description: "" })])
    }

    const editProgram = (newValue, index, type) => {
        setPrograms(prev => {
            prev = prev.map((v, i) => {
                if (index === i) {
                    return ({ ...v, [type]: newValue })
                }
                return ({ ...v })
            });
            return prev;
        })
    }

    const removeProgram = (index) => {
        setPrograms(prev => prev.filter((_, i) => index !== i));
    }

    return (
        <>
            {/* programs */}
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Button appearance="primary" onClick={() => newProgram()}>
                    Program Ekle
                </Button>
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                {programs?.map((el, index) => (
                    <div key={index} className='program_item'>
                        <h5 className='program_number'>{`Program ${index + 1}`}</h5>
                        <CustomInput
                            title={"Başlık"}
                            children={
                                <Input value={el?.title} onChange={(value) => editProgram(value, index, "title")} />
                            }
                        />
                        <CustomInput
                            title={"Tanıtım"}
                            children={
                                <Input value={el?.description} onChange={(value) => editProgram(value, index, "description")} as="textarea" rows={4} />
                            }
                        />
                        <IconButton color="red" appearance="primary" size="xs" className='close-btn' circle icon={<CloseIcon />} onClick={() => removeProgram(index)} />
                    </div>
                ))}
            </Col>
        </>
    )
}

export default TourPrograms