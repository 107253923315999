
export const LOGIN = '/admin/login';
export const LOGOUT = '/logout';

export const CATEGORY_URL = '/categories';
export const SUB_CATEGORY_URL = '/sub-categories';
export const TOUR_URL = '/tours';
export const AGENCY_URL = '/agencies';
export const ROLE_URL = '/roles';
export const PERMISSION_URL = '/permissions';
export const STAFF = '/staff';
export const USER_URL = '/users';
export const HOTEL_URL = '/hotels';
export const ROOM_URL = '/rooms';
export const ROOM_TYPES_URL = '/room-types';
export const POLICY_URL = '/policies';
export const TRANSFER_URL = '/transfers';
export const EDUCATION_URL = '/education';
export const BANNER_URL = '/banners';
export const FACILITY_URL = '/facilities';
