import {
    POLICIES_DATA,
} from "./constants";

const initialState = {
    policies_data: null,
};

const policyReducer = (state = initialState, action) => {
    switch (action.type) {

        case POLICIES_DATA:
            return {
                ...state,
                policies_data: action.data,
            };
        default:
            return state;
    }
};

export default policyReducer;
