
import { call, put, all, takeLatest } from "redux-saga/effects";
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction } from "../../../redux/actions";
import { CREATE_PERMISSION, DELETE_PERMISSION, GET_PERMISSIONS, UPDATE_PERMISSION } from "./constants";
import { createPermission, deletePermission, getPermissions, updatePermission } from "../../../../services/services";
import { permissionsDataAction } from "./actions";

function* createPermissionSaga({ body, callback }) {
    try {
        const response = yield call(createPermission, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* updatePermissionSaga({ id, body, callback }) {
    try {
        const response = yield call(updatePermission, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deletePermissionSaga({ id, callback }) {
    try {
        const response = yield call(deletePermission, id);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(id);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getPermissionsSaga({ pagination, page, limit, callback }) {
    try {
        const response = yield call(getPermissions, pagination, page, limit);
        if (response.status === 200) {
            yield put(permissionsDataAction(response.data?.data ?? ""));
            if (callback) {
                callback(response.data?.data ?? "");
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(CREATE_PERMISSION, createPermissionSaga),
    takeLatest(UPDATE_PERMISSION, updatePermissionSaga),
    takeLatest(DELETE_PERMISSION, deletePermissionSaga),
    takeLatest(GET_PERMISSIONS, getPermissionsSaga),
]);