import { EURO, GBP, TL, USD } from "./constants";

export const disablePastDates = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set the time to the start of the day
    return date < today; // Disable dates that are before today
};


export const validateUsername = (value) => {
    if (!value) return "Username is required";
    if (/\s/.test(value)) return "Username cannot contain spaces";
    if (!/^[a-zA-Z0-9_]+$/.test(value)) return "Username can only contain letters, numbers, and underscores";
    if (value.length < 3) return "Username must be at least 3 characters long";
    if (value.length > 20) return "Username must be less than 20 characters";
    return true;
};


export const getRoleTitle = (value) => {
    switch (value) {
        case 1:
            return "Admin";
        case 2:
            return "Kullanıcı";
        case 3:
            return "Agenta";
        default:
            return "Rol tanımlanmamış";
    }
}

export const getPriceType = (value) => {
    switch (value) {
        case "TRY":
            return TL;
        case "EUR":
            return EURO;
        case "USD":
            return USD;
        case "GBP":
            return GBP;
        default:
            return "";
    }
}

// Checking has permission
export const hasPermission = (permissions, requiredPermission) => {
    return permissions?.length > 0 ? permissions.some(permission => permission.name === requiredPermission) : false;
};