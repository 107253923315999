import { DELETE_AGENCY, CREATE_AGENCY, UPDATE_AGENCY, GET_AGENCIES, AGENCIES_DATA, UPLOAD_IMAGES_AGENCY, DELETE_IMAGE_AGENCY, GET_AGENCY_BY_ID, AGENCY_BY_ID_DATA } from "./constants";

export const saveAgencyAction = (body, callback) => ({
    type: CREATE_AGENCY,
    body,
    callback
});

export const updateAgencyAction = (id, body, callback) => ({
    type: UPDATE_AGENCY,
    id,
    body,
    callback
});

export const deleteAgencyAction = (id, callback) => ({
    type: DELETE_AGENCY,
    id,
    callback
});
export const uploadImagesAgencyAction = (id, body, callback) => ({
    type: UPLOAD_IMAGES_AGENCY,
    id,
    body,
    callback
});

export const deleteImageAgencyAction = (id, body, callback) => ({
    type: DELETE_IMAGE_AGENCY,
    id,
    body,
    callback
});


export const getAgenciesAction = (page, limit) => ({
    type: GET_AGENCIES,
    page,
    limit
});

export const getAgencyByIdAction = (id, callback) => ({
    type: GET_AGENCY_BY_ID,
    id,
    callback
});

export const agenciesDataAction = (data) => ({
    type: AGENCIES_DATA,
    data
});

export const agencyByIdDataAction = (data) => ({
    type: AGENCY_BY_ID_DATA,
    data
});
