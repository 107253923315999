
import { call, put, all, takeLatest } from "redux-saga/effects";
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction } from "../../redux/actions";
import { CREATE_BANNER, DELETE_BANNER, DELETE_IMAGE_BANNER, GET_BANNERS, UPDATE_BANNER, UPLOAD_IMAGES_BANNER } from "./constants";
import { createBanner, deleteBanner, getBanners, imageDeleteBanner, imagesUploadBanner, updateBanner } from "../../../services/services";
import { bannersDataAction } from "./actions";

function* uploadImagesBannerSaga({ id, body, callback }) {
    try {
        const response = yield call(imagesUploadBanner, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            debugger
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteImageBannerSaga({ id, body, callback }) {
    try {
        const response = yield call(imageDeleteBanner, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.message);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* createBannerSaga({ body, callback }) {
    try {
        const response = yield call(createBanner, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* updateBannerSaga({ id, body, callback }) {
    try {
        const response = yield call(updateBanner, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteBannerSaga({ id, callback }) {
    try {
        const response = yield call(deleteBanner, id);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(id);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getBannersSaga({ page, limit }) {
    try {
        const response = yield call(getBanners, page, limit);
        if (response.status === 200) {
            yield put(bannersDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error?.response?.message ?? error.response?.data?.errors ?? error }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(CREATE_BANNER, createBannerSaga),
    takeLatest(UPDATE_BANNER, updateBannerSaga),
    takeLatest(UPLOAD_IMAGES_BANNER, uploadImagesBannerSaga),
    takeLatest(DELETE_IMAGE_BANNER, deleteImageBannerSaga),
    takeLatest(DELETE_BANNER, deleteBannerSaga),
    takeLatest(GET_BANNERS, getBannersSaga),
]);