
import { call, put, all, takeLatest } from "redux-saga/effects";
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction } from "../../redux/actions";
import { CREATE_AGENCY, DELETE_AGENCY, DELETE_IMAGE_AGENCY, GET_AGENCIES, GET_AGENCY_BY_ID, UPDATE_AGENCY, UPLOAD_IMAGES_AGENCY } from "./constants";
import { createAgency, deleteAgency, getAgencies, getAgencyById, imageDeleteAgency, imagesUploadAgency, updateAgency } from "../../../services/services";
import { agenciesDataAction, agencyByIdDataAction } from "./actions";

function* uploadImagesAgencySaga({ id, body, callback }) {
    try {
        const response = yield call(imagesUploadAgency, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            debugger
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteImageAgencySaga({ id, body, callback }) {
    try {
        const response = yield call(imageDeleteAgency, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.message);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* createAgencySaga({ body, callback }) {
    try {
        const response = yield call(createAgency, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* updateAgencySaga({ id, body, callback }) {
    try {
        const response = yield call(updateAgency, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteAgencySaga({ id, callback }) {
    try {
        const response = yield call(deleteAgency, id);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(id);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getAgenciesSaga({ page, limit }) {
    try {
        const response = yield call(getAgencies, page, limit);
        if (response.status === 200) {
            yield put(agenciesDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getAgencyByIdSaga({ id, callback }) {
    try {
        const response = yield call(getAgencyById, id);
        if (response.status === 200) {
            yield put(agencyByIdDataAction(response.data?.data ?? ""));
            if (callback) {
                callback(response?.data?.data)
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(CREATE_AGENCY, createAgencySaga),
    takeLatest(UPDATE_AGENCY, updateAgencySaga),
    takeLatest(UPLOAD_IMAGES_AGENCY, uploadImagesAgencySaga),
    takeLatest(DELETE_IMAGE_AGENCY, deleteImageAgencySaga),
    takeLatest(DELETE_AGENCY, deleteAgencySaga),
    takeLatest(GET_AGENCIES, getAgenciesSaga),
    takeLatest(GET_AGENCY_BY_ID, getAgencyByIdSaga),
]);