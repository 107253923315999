import React from 'react'
import { Button, Col, DatePicker, DateRangePicker, IconButton, Input, InputGroup, Row, SelectPicker } from 'rsuite';
import { CustomInput } from '../../../components/CustomComponents';
import CloseIcon from '@rsuite/icons/Close';
import { disablePastDates } from '../../../utils/helpers';
import { CURRENCIES } from '../../../utils/constants';

const TourDatesPrices = (props) => {

    const { dateAndPrices, setDatesAndPrices } = props;

    const newDate = () => {
        setDatesAndPrices(prev => [...prev, ({
            start_date: new Date(new Date().setDate(new Date().getDate() + 1)),
            end_date: new Date(new Date().setDate(new Date().getDate() + 2)),
            date_prices: [{
                discount: "", to_members_discount: "", single_accommodation: "",
                adult_price: "", child_price: "", baby_price: "", currency: "USD",
                hotels_type: ""
            }, ...prev?.date_prices ?? []]
        })])
    }

    const addDatePrice = (index) => {
        setDatesAndPrices(prev => {
            const updatedDates = [...prev];
            updatedDates[index].date_prices.push({
                discount: "", to_members_discount: "", single_accommodation: "",
                adult_price: "", child_price: "", baby_price: "", currency: "USD",
                hotels_type: ""
            });
            return updatedDates;
        });
    };

    const editDatePrice = (newValue, index, type, priceIndex) => {
        setDatesAndPrices(prev => {
            prev = prev.map((date, i) => {
                if (index === i) {
                    if (type === "start_date" || type === "end_date" && priceIndex === undefined) {
                        return ({ ...date, [type]: newValue })
                    } else {
                        return ({
                            ...date,
                            date_prices: date.date_prices.map((prices, pi) => {
                                if (pi === priceIndex) {
                                    return ({ ...prices, [type]: newValue })
                                }
                                return ({ ...prices });
                            })
                        })
                    }
                }
                return ({ ...date })
            });
            return prev;
        })
    }

    const removeDatePrice = (index) => {
        setDatesAndPrices(prev => prev.filter((_, i) => index !== i));
    }

    const removeDatePriceItem = (dateIndex, priceIndex) => {
        setDatesAndPrices(prev => {
            const updatedDates = [...prev];
            const datePrices = [...updatedDates[dateIndex].date_prices];

            datePrices.splice(priceIndex, 1);
            updatedDates[dateIndex].date_prices = datePrices;

            return updatedDates;
        });
    };

    return (
        <>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button appearance="primary" onClick={() => newDate()}>
                        Tarih Ekle
                    </Button>
                </Col>
            </Col>

            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                {dateAndPrices?.map((el, index) => (
                    <div key={index} className='program_item'>
                        <h5 className='program_number'>{`Tarih sırası: ${index + 1}`}</h5>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <CustomInput
                                    title={"Başlangıç tarih"}
                                    children={
                                        <DatePicker
                                            value={el?.start_date ?? new Date(new Date().setDate(new Date().getDate() + 1))}
                                            onChange={(value) => editDatePrice(value, index, "start_date")}
                                            shouldDisableDate={disablePastDates}
                                            format="yyyy-MM-dd HH:mm"
                                        />
                                    }
                                />
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <CustomInput
                                    title={"Bitiş tarih"}
                                    children={
                                        <DatePicker
                                            value={el?.end_date ?? new Date(new Date().setDate(new Date().getDate() + 2))}
                                            onChange={(value) => editDatePrice(value, index, "end_date")}
                                            shouldDisableDate={disablePastDates}
                                            format="yyyy-MM-dd HH:mm"
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h5>Seçilmiş tarihdeki tur Fiyatlandırma</h5>
                            <Button appearance="primary" onClick={() => addDatePrice(index)}>
                                Fiyat Ekle
                            </Button>
                        </Col>
                        {el?.date_prices?.map((price, priceIndex) => (
                            <Row key={priceIndex} className='date_price_item'>
                                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                    <CustomInput
                                        title={"Yetişkin Fiyatı"}
                                        children={
                                            <Input type='number' value={price?.adult_price} onChange={(value) => editDatePrice(value, index, "adult_price", priceIndex)} />
                                        }
                                    />
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                    <CustomInput
                                        title={"Çocuk Fiyatı"}
                                        children={
                                            <Input type='number' value={price?.child_price} onChange={(value) => editDatePrice(value, index, "child_price", priceIndex)} />
                                        }
                                    />
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                    <CustomInput
                                        title={"Bebek Fiyatı"}
                                        children={
                                            <Input type='number' value={price?.baby_price} onChange={(value) => editDatePrice(value, index, "baby_price", priceIndex)} />
                                        }
                                    />
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                    <CustomInput
                                        title={"Indirim"}
                                        children={
                                            <InputGroup>
                                                <Input type='number' min={0} max={100} value={price?.discount} onChange={(value) => editDatePrice(value, index, "discount", priceIndex)} />
                                                <InputGroup.Addon>%</InputGroup.Addon>
                                            </InputGroup>
                                        }
                                    />
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                    <CustomInput
                                        title={"Üyeler için indirim"}
                                        children={
                                            <InputGroup>
                                                <Input type='number' min={0} max={100} value={price?.to_members_discount} onChange={(value) => editDatePrice(value, index, "to_members_discount", priceIndex)} />
                                                <InputGroup.Addon>%</InputGroup.Addon>
                                            </InputGroup>
                                        }
                                    />
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                    <CustomInput
                                        title={"Single Konaklama"}
                                        children={
                                            <Input type='number' value={price?.single_accommodation} onChange={(value) => editDatePrice(value, index, "single_accommodation", priceIndex)} />
                                        }
                                    />
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                    <CustomInput
                                        title={"Para Birimi"}
                                        children={
                                            <SelectPicker
                                                data={CURRENCIES.map(item => ({ label: item.title, value: item.value }))}
                                                value={price?.currency}
                                                onChange={(value) => editDatePrice(value, index, "currency", priceIndex)}
                                                style={{ width: 300 }}
                                            />
                                        }
                                    />
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                    <CustomInput
                                        title={"Otel Türü"}
                                        children={
                                            <Input value={price?.hotels_type} onChange={(value) => editDatePrice(value, index, "hotels_type", priceIndex)} />
                                        }
                                    />
                                </Col>
                                <IconButton color="red" appearance="primary" size="xs" className='close-btn' circle icon={<CloseIcon />}
                                    onClick={() => removeDatePriceItem(index, priceIndex)}
                                />
                            </Row>
                        ))}

                        <IconButton color="red" appearance="primary" size="xs" className='close-btn' circle icon={<CloseIcon />} onClick={() => removeDatePrice(index)} />
                    </div>
                ))}
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>

            </Col>
        </>
    )
}

export default TourDatesPrices