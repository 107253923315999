import {
    USERS_DATA,
} from "./constants";

const initialState = {
    users_data: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case USERS_DATA:
            return {
                ...state,
                users_data: action.data,
            };
        default:
            return state;
    }
};

export default userReducer;
