import { combineReducers } from 'redux';
import commonReducer from '../pages/redux/reducer';
import tourReducer from '../pages/tours/redux/reducer';
import categoryReducer from '../pages/category/redux/reducer';
import subCategoryReducer from '../pages/subCategory/redux/reducer';
import agencyReducer from '../pages/agency/redux/reducer';
import staffReducer from '../pages/staff/redux/reducer';
import userReducer from '../pages/users/redux/reducer';
import roleReducer from '../pages/role/redux/reducer';
import hotelReducer from '../pages/hotels/redux/reducer';
import roomReducer from '../pages/rooms/redux/reducer';
import roomTypeReducer from '../pages/definitions/roomType/redux/reducer';
import bannerReducer from '../pages/banners/redux/reducer';
import facilityReducer from '../pages/definitions/facilities/redux/reducer';
import permissionReducer from '../pages/definitions/permissions/redux/reducer';
import policyReducer from '../pages/definitions/policies/redux/reducer';

//all application reducers here
const reduc = combineReducers({
    commonReducer,
    tourReducer,
    categoryReducer,
    subCategoryReducer,
    agencyReducer,
    staffReducer,
    userReducer,
    roleReducer,
    hotelReducer,
    roomReducer,
    roomTypeReducer,
    bannerReducer,
    facilityReducer,
    permissionReducer,
    policyReducer,
  });
  
  export const combinedReducers = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
      state = undefined;
    }
    return reduc(state, action);
  };
  