import { DELETE_SUB_CATEGORY, CREATE_SUB_CATEGORY, UPDATE_SUB_CATEGORY, GET_SUB_CATEGORIES, SUB_CATEGORIES_DATA, UPLOAD_IMAGES_SUB_CATEGORY, DELETE_IMAGE_SUB_CATEGORY } from "./constants";

export const saveSubCategoryAction = (body, callback) => ({
    type: CREATE_SUB_CATEGORY,
    body,
    callback
});

export const updateSubCategoryAction = (id, body, callback) => ({
    type: UPDATE_SUB_CATEGORY,
    id,
    body,
    callback
});

export const deleteSubCategoryAction = (id, callback) => ({
    type: DELETE_SUB_CATEGORY,
    id,
    callback
});

export const getSubCategoriesAction = (page, limit) => ({
    type: GET_SUB_CATEGORIES,
    page,
    limit
});

export const subCategoriesDataAction = (data) => ({
    type: SUB_CATEGORIES_DATA,
    data
});


export const uploadImagesSubCategoryAction = (id, body, callback) => ({
    type: UPLOAD_IMAGES_SUB_CATEGORY,
    id,
    body,
    callback
});

export const deleteImageSubCategoryAction = (id, body, callback) => ({
    type: DELETE_IMAGE_SUB_CATEGORY,
    id,
    body,
    callback
});
