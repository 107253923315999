// src/pages/content/agency_leaders/redux/reducer.js
import {
    STAFF_DATA,
} from "./constants";

const initialState = {
    staff_data: [],
};

const staffReducer = (state = initialState, action) => {
    switch (action.type) {

        case STAFF_DATA:
            return {
                ...state,
                staff_data: action.data,
            };
        default:
            return state;
    }
};

export default staffReducer;
