
import { call, put, all, takeLatest } from "redux-saga/effects";
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction } from "../../redux/actions";
import { CREATE_SUB_CATEGORY, DELETE_IMAGE_SUB_CATEGORY, DELETE_SUB_CATEGORY, GET_SUB_CATEGORIES, UPDATE_SUB_CATEGORY, UPLOAD_IMAGES_SUB_CATEGORY } from "./constants";
import { createSubCategory, deleteSubCategory, getSubCategories, imageDeleteSubCategory, imagesUploadSubCategory, updateSubCategory } from "../../../services/services";
import { subCategoriesDataAction } from "./actions";



function* uploadImagesSubCategorySaga({ id, body, callback }) {
    try {
        const response = yield call(imagesUploadSubCategory, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteImageSubCategorySaga({ id, body, callback }) {
    try {
        const response = yield call(imageDeleteSubCategory, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.message);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* createSubCategorySaga({ body, callback }) {
    try {
        const response = yield call(createSubCategory, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else  {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* updateSubCategorySaga({ id, body, callback }) {
    try {
        const response = yield call(updateSubCategory, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else  {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteSubCategorySaga({ id, callback }) {
    try {
        const response = yield call(deleteSubCategory, id);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(id);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else  {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getSubCategoriesSaga({ page, limit }) {
    try {
        const response = yield call(getSubCategories, page, limit);
        if (response.status === 200) {
            yield put(subCategoriesDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction(error.response?.data?.errors.map((errorObj) => Object.values(errorObj)[0])));
            const errorMessages = error.response?.data?.errors.map((errorObj) => Object.values(errorObj)[0]).join('\n');
            alert(`${error.response?.status} => ${errorMessages}`);
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(UPLOAD_IMAGES_SUB_CATEGORY, uploadImagesSubCategorySaga),
    takeLatest(DELETE_IMAGE_SUB_CATEGORY, deleteImageSubCategorySaga),
    takeLatest(CREATE_SUB_CATEGORY, createSubCategorySaga),
    takeLatest(UPDATE_SUB_CATEGORY, updateSubCategorySaga),
    takeLatest(DELETE_SUB_CATEGORY, deleteSubCategorySaga),
    takeLatest(GET_SUB_CATEGORIES, getSubCategoriesSaga),
]);