// src/pages/content/categories/redux/reducer.js
import {
    CATEGORIES_DATA,
} from "./constants";

const initialState = {
    categories_data: null,
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {

        case CATEGORIES_DATA:
            return {
                ...state,
                categories_data: action.data,
            };
        default:
            return state;
    }
};

export default categoryReducer;
