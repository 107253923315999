// src/pages/content/banners/redux/reducer.js
import {
    BANNERS_DATA,
} from "./constants";

const initialState = {
    banners_data: null,
};

const bannerReducer = (state = initialState, action) => {
    switch (action.type) {

        case BANNERS_DATA:
            return {
                ...state,
                banners_data: action.data,
            };
        default:
            return state;
    }
};

export default bannerReducer;
