
import { call, put, all, takeLatest } from "redux-saga/effects";
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction } from "../../redux/actions";
import { CREATE_ROOM, DELETE_ROOM, GET_ROOMS, GET_ROOMS_BY_HOTEL_ID, GET_ROOM_BY_NAME, UPDATE_ROOM, } from "./constants";
import { createRoom, deleteRoom, getRoomByName, getRooms, getRoomsByHotelId, updateRoom } from "../../../services/services";
import { roomsDataAction } from "./actions";


function* createRoomSaga({ body, callback }) {
    try {
        const response = yield call(createRoom, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* updateRoomSaga({ id, body, callback }) {
    try {
        const response = yield call(updateRoom, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteRoomSaga({ id, callback }) {
    try {
        const response = yield call(deleteRoom, id);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(id);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getRoomByNameSaga({ name, callback }) {
    try {
        const response = yield call(getRoomByName, name);
        if (response.status === 200) {
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getRoomsSaga({ page, limit }) {
    try {
        const response = yield call(getRooms, page, limit);
        if (response.status === 200) {
            yield put(roomsDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getRoomsByHotelIdSaga({ queryParams, callback }) {
    try {
        const response = yield call(getRoomsByHotelId, queryParams);
        if (response.status === 200) {
            yield put(roomsDataAction(response.data?.data ?? ""));
            if (callback) {
                callback(response.data?.data)
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(CREATE_ROOM, createRoomSaga),
    takeLatest(UPDATE_ROOM, updateRoomSaga),
    takeLatest(DELETE_ROOM, deleteRoomSaga),
    takeLatest(GET_ROOMS, getRoomsSaga),
    takeLatest(GET_ROOMS_BY_HOTEL_ID, getRoomsByHotelIdSaga),
    takeLatest(GET_ROOM_BY_NAME, getRoomByNameSaga),
]);