import { DELETE_HOTEL, CREATE_HOTEL, UPDATE_HOTEL, GET_HOTELS, GET_HOTEL_BY_NAME, HOTELS_DATA, UPLOAD_IMAGES_HOTEL, DELETE_IMAGE_HOTEL, GET_HOTELS_BY_AGENCY } from "./constants";

export const saveHotelAction = (body, callback) => ({
    type: CREATE_HOTEL,
    body,
    callback
});

export const updateHotelAction = (id, body, callback) => ({
    type: UPDATE_HOTEL,
    id,
    body,
    callback
});

export const deleteHotelAction = (id, callback) => ({
    type: DELETE_HOTEL,
    id,
    callback
});

export const getHotelsAction = (page, limit) => ({
    type: GET_HOTELS,
    page,
    limit
});

export const getHotelsByAgencyAction = (queryParams) => ({
    type: GET_HOTELS_BY_AGENCY,
    queryParams
});

export const getHotelByNameAction = (name, callback) => ({
    type: GET_HOTEL_BY_NAME,
    name,
    callback
});

export const hotelsDataAction = (data) => ({
    type: HOTELS_DATA,
    data
});

export const uploadImagesHotelAction = (id, body, callback) => ({
    type: UPLOAD_IMAGES_HOTEL,
    id,
    body,
    callback
});

export const deleteImageHotelAction = (id, body, callback) => ({
    type: DELETE_IMAGE_HOTEL,
    id,
    body,
    callback
});
