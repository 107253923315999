import { DELETE_ROOM_TYPE, CREATE_ROOM_TYPE, UPDATE_ROOM_TYPE, GET_ROOM_TYPES, GET_ROOM_TYPE_BY_NAME, ROOM_TYPES_DATA, UPLOAD_IMAGES_ROOM_TYPE, DELETE_IMAGE_ROOM_TYPE,  } from "./constants";

export const saveRoomTypeAction = (body, callback) => ({
    type: CREATE_ROOM_TYPE,
    body,
    callback
});

export const updateRoomTypeAction = (id, body, callback) => ({
    type: UPDATE_ROOM_TYPE,
    id,
    body,
    callback
});

export const deleteRoomTypeAction = (id, callback) => ({
    type: DELETE_ROOM_TYPE,
    id,
    callback
});

export const getRoomTypesAction = (page, limit) => ({
    type: GET_ROOM_TYPES,
    page,
    limit
});

export const getRoomTypeByNameAction = (name, callback) => ({
    type: GET_ROOM_TYPE_BY_NAME,
    name,
    callback
});

export const roomTypesDataAction = (data) => ({
    type: ROOM_TYPES_DATA,
    data
});

export const uploadImagesRoomTypeAction = (id, body, callback) => ({
    type: UPLOAD_IMAGES_ROOM_TYPE,
    id,
    body,
    callback
});

export const deleteImageRoomTypeAction = (id, body, callback) => ({
    type: DELETE_IMAGE_ROOM_TYPE,
    id,
    body,
    callback
});
