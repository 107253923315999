
import { call, put, all, takeLatest } from "redux-saga/effects";
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction } from "../../../redux/actions";
import { CREATE_ROOM_TYPE, DELETE_IMAGE_ROOM_TYPE, DELETE_ROOM_TYPE, GET_ROOM_TYPES, GET_ROOM_TYPE_BY_NAME, UPDATE_ROOM_TYPE, UPLOAD_IMAGES_ROOM_TYPE } from "./constants";
import { createRoomType, deleteRoomType, getRoomTypeByName, getRoomTypes, imageDeleteRoomType, imagesUploadRoomType, updateRoomType } from "../../../../services/services";
import { roomTypesDataAction } from "./actions";


function* uploadImagesRoomTypeSaga({ id, body, callback }) {
    try {
        const response = yield call(imagesUploadRoomType, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            debugger
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteImageRoomTypeSaga({ id, body, callback }) {
    try {
        const response = yield call(imageDeleteRoomType, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.message);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* createRoomTypeSaga({ body, callback }) {
    try {
        const response = yield call(createRoomType, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* updateRoomTypeSaga({ id, body, callback }) {
    try {
        const response = yield call(updateRoomType, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteRoomTypeSaga({ id, callback }) {
    try {
        const response = yield call(deleteRoomType, id);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(id);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getRoomTypeByNameSaga({ name, callback }) {
    try {
        const response = yield call(getRoomTypeByName, name);
        if (response.status === 200) {
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getRoomTypesSaga({ page, limit }) {
    try {
        const response = yield call(getRoomTypes, page, limit);
        if (response.status === 200) {
            yield put(roomTypesDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}


export default all([
    takeLatest(CREATE_ROOM_TYPE, createRoomTypeSaga),
    takeLatest(UPDATE_ROOM_TYPE, updateRoomTypeSaga),
    takeLatest(DELETE_ROOM_TYPE, deleteRoomTypeSaga),
    takeLatest(UPLOAD_IMAGES_ROOM_TYPE, uploadImagesRoomTypeSaga),
    takeLatest(DELETE_IMAGE_ROOM_TYPE, deleteImageRoomTypeSaga),
    takeLatest(GET_ROOM_TYPES, getRoomTypesSaga),
    takeLatest(GET_ROOM_TYPE_BY_NAME, getRoomTypeByNameSaga),
]);