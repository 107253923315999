import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { Avatar, AvatarGroup, Button, ButtonToolbar, Col, Container, Dropdown, Grid, IconButton, Pagination, Popover, Row, Table, Tooltip, Whisper, useToaster, } from 'rsuite';
import { connect } from 'react-redux';
import RolesCUModal from './RolesCUModal';
import { rolesDataAction, deleteRoleAction, getRolesAction } from "./redux/actions"
import MoreIcon from '@rsuite/icons/legacy/More';
import { PermissionsWrapper, SwalDeleteConfirm, renderMenu } from '../../components/CustomComponents';
import { hasPermission } from '../../utils/helpers';

const ActionCell = ({ rowData, dataKey, callback, ...props }) => {
  return (
    <Table.Cell {...props} className="link-group">
      <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu(rowData, callback, false, false, "role")}>
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
    </Table.Cell>
  );
};

const Roles = (props) => {

  const [open, setOpen] = useState({ edit: false, create: false });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState(false);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    if (hasPermission(props.user?.role?.permissions, "role view")) {
      props.getRolesAction(page, limit);
    }
  }, [page, limit])

  const editHandler = (data, type) => {
    if (data) {
      setEditData(data);
      setOpen(prev => ({ ...prev, [type]: true }));
    }
  }

  const deleteHandler = (id) => {
    if (id) {
      SwalDeleteConfirm({
        onConfirm: (e) => {
          props.deleteRoleAction(id, deleteCallback);
        },
        onCancel: (e) => { debugger }
      })
    }
  }

  const deleteCallback = useCallback((id) => {
    if (id) {
      let filteredList = props.roles_data.data?.filter(item => item.id !== id);
      props.rolesDataAction({ ...props.roles_data, data: filteredList });
    }
  }, []);

  const actionHandler = useCallback((eventKey, rowData) => {
    switch (eventKey) {
      case 3:
        editHandler(rowData, "edit")
        break;
      case 4:
        deleteHandler(rowData.id)
        break;
      default:
        break;
    }

  }, [])
  console.log("props.roles_data", props.roles_data)
  return (
    <PermissionsWrapper permission="role view">
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
          <h2>Roller</h2>
          {hasPermission(props.user?.role?.permissions, "role create") &&
            <Button appearance="primary" onClick={() => setOpen(prev => ({ ...prev, create: true }))} >
              Rol Oluştur
            </Button>
          }
        </div>
        <div style={{ height: 500, marginTop: '30px' }}>
          <Table data={props.roles_data?.data || []} bordered rowHeight={60} fillHeight>

            <Table.Column width={200} flexGrow={2}>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.Cell dataKey="title" />
            </Table.Column>

            <Table.Column width={200} flexGrow={1}>
              <Table.HeaderCell>Kullanıcılar</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <AvatarGroup stack>
                    {rowData?.staff?.filter((_, i) => i < 4)?.map(user => (
                      <Whisper
                        key={user?.fullName || ""}
                        placement="top"
                        trigger="hover"
                        speaker={<Tooltip>{user?.fullName || "Unknown"}</Tooltip>}
                      >
                        <Avatar bordered circle key={user?.fullName || ""} src={user?.thumbnail || "https://cdn.pixabay.com/photo/2023/02/18/11/00/icon-7797704_1280.png"} alt={user?.fullName || ""} />
                      </Whisper>
                    ))}
                    {rowData?.staffCount > 4 ? (
                      <Avatar bordered circle style={{ background: '#111' }}>
                        +{rowData?.staffCount - 4}
                      </Avatar>
                    ) : null}
                  </AvatarGroup>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={100} fixed>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <ActionCell dataKey="id" callback={actionHandler} />
            </Table.Column>
          </Table>
        </div>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager']}
            total={props.roles_data?.total}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
        {open.create && hasPermission(props.user?.role?.permissions, "role create") ? <RolesCUModal setOpen={setOpen} open={open.create} type={"create"} /> : null}

        {open.edit ? <RolesCUModal setOpen={setOpen} open={open.edit} editData={editData} type={"edit"} setEditData={setEditData} /> : null}

      </div>
    </PermissionsWrapper>
  );
};
const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
  roles_data: state.roleReducer.roles_data,
  common_error: state.commonReducer.common_error,
  user: state.commonReducer.user,
});

const mapDispatchToProps = dispatch => ({
  getRolesAction: (page, limit) => dispatch(getRolesAction(page, limit)),
  deleteRoleAction: (id, callback) => dispatch(deleteRoleAction(id, callback)),
  rolesDataAction: (data) => dispatch(rolesDataAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
