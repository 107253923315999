import { DELETE_ROOM, CREATE_ROOM, UPDATE_ROOM, GET_ROOMS, GET_ROOM_BY_NAME, ROOMS_DATA, GET_ROOMS_BY_HOTEL_ID, } from "./constants";

export const saveRoomAction = (body, callback) => ({
    type: CREATE_ROOM,
    body,
    callback
});

export const updateRoomAction = (id, body, callback) => ({
    type: UPDATE_ROOM,
    id,
    body,
    callback
});

export const deleteRoomAction = (id, callback) => ({
    type: DELETE_ROOM,
    id,
    callback
});

export const getRoomsAction = (page, limit) => ({
    type: GET_ROOMS,
    page,
    limit
});

export const getRoomsByHotelIdAction = (queryParams, callback) => ({
    type: GET_ROOMS_BY_HOTEL_ID,
    queryParams,
    callback
});

export const getRoomByNameAction = (name, callback) => ({
    type: GET_ROOM_BY_NAME,
    name,
    callback
});

export const roomsDataAction = (data) => ({
    type: ROOMS_DATA,
    data
});