
import { call, put, all, takeLatest } from "redux-saga/effects";
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction } from "../../../redux/actions";
import { CREATE_FACILITY, DELETE_IMAGE_FACILITY, DELETE_FACILITY, GET_FACILITIES, UPDATE_FACILITY, UPLOAD_IMAGES_FACILITY } from "./constants";
import { createFacility, deleteFacility, getFacilityByName, getFacilities, getFacilitiesByAgency, imageDeleteFacility, imagesUploadFacility, updateFacility } from "../../../../services/services";
import { facilitiesDataAction } from "./actions";


function* createFacilitySaga({ body, callback }) {
    try {
        const response = yield call(createFacility, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* updateFacilitySaga({ id, body, callback }) {
    try {
        const response = yield call(updateFacility, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteFacilitySaga({ id, callback }) {
    try {
        const response = yield call(deleteFacility, id);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(id);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getFacilitiesSaga({ page, limit }) {
    try {
        const response = yield call(getFacilities, page, limit);
        if (response.status === 200) {
            yield put(facilitiesDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(CREATE_FACILITY, createFacilitySaga),
    takeLatest(UPDATE_FACILITY, updateFacilitySaga),
    takeLatest(DELETE_FACILITY, deleteFacilitySaga),
    takeLatest(GET_FACILITIES, getFacilitiesSaga),
]);