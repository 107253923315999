import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { roomTypesDataAction, saveRoomTypeAction, updateRoomTypeAction } from './redux/actions';
import { Button, CheckPicker, Col, Form, Grid, Input, Modal, SelectPicker, Tooltip, Whisper } from 'rsuite';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from '../../../components/CustomComponents';
import { getFacilitiesAction } from '../facilities/redux/actions';

const RoomTypeCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;

    const {
        handleSubmit,
        control, reset, watch, setValue, getValues,
        formState: { errors }
    } = useForm();

    const [facilitiesItems, setFacilitiesItems] = useState([]);
    const facilitiesUpdateData = () => {
        setFacilitiesItems(props.facilities_data?.data?.map(item => ({ label: item.name, value: item.id })) ?? []);
    };


    useEffect(() => {
        props.getFacilitiesAction(1, 500);
    }, [])


    useEffect(() => {
        if (editData) {
            Object.entries(editData).forEach(([key, value]) => {
                if (key === "roomTypeFacilities") {
                    setValue("facilities", value?.map(el => el.id) ?? []);
                } else if (Boolean(value)) {
                    setValue(key, value);
                }
            })
        }
    }, [editData])

    const onSubmit = data => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value], index) => {
            if (key === "size") {
                formData.append(key, Boolean(value) ? parseFloat(value) : 0);
            } else if (key === "max_occupancy") {
                formData.append(key, Boolean(value) ? parseInt(value) : 0);
            } else if (key === "facilities") {
                value?.forEach(item => {
                    formData.append(`${key}[]`, parseInt(item));
                })
            } else {
                formData.append(key, value);
            }
        })

        if (editData) {
            props.updateRoomTypeAction(editData.id, formData, callback)
        } else {
            props.saveRoomTypeAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.roomTypes_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.roomTypes_data.data, data];
            }
            props.roomTypesDataAction({ ...props.roomTypes_data, data: updatedTableList, total: props.roomTypes_data.total + 1 });
            resetFields();
        }
    }, [])

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset();
        setOpen(prev => ({ ...prev, [type]: false }))
    }

    return (
        <Modal backdrop={"static"} size={"sm"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Oda Tipi Ekle</Modal.Title>
            </Modal.Header>
            <Form fluid onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid fluid className='tour_form'>
                        {/* name */}
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Oda Ismi"}
                                        required
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input value={field?.value} onChange={field?.onChange} placeholder='e.g., King RoomType, Suite' />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{ required: "Gerekli" }}
                            />
                        </Col>
                        {/* bed_type */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Controller
                                name="bed_type"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Yatak Tipi"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input value={field?.value} onChange={field?.onChange} placeholder='e.g., King Bed, Twin Beds' />
                                        }
                                    />
                                )}
                            />
                        </Col>
                        {/* facilities */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Controller
                                name="facilities"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Oda Olanakları"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <CheckPicker
                                                data={facilitiesItems}
                                                value={field?.value}
                                                onChange={field?.onChange}
                                                onOpen={facilitiesUpdateData}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Col>
                        {/* size */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Controller
                                name="size"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Boyut"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input type='number' value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                            />
                        </Col>
                        {/* max_occupancy */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Controller
                                name="max_occupancy"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Maksimum Doluluk"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input type='number' value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                            />
                        </Col>
                        {/* description */}
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Tanıtım"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input as={"textarea"} rows={4} value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                            />
                        </Col>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary" loading={props.common_requesting}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    roomTypes_data: state.roomTypeReducer.roomTypes_data,
    facilities_data: state.facilityReducer.facilities_data,
});

const mapDispatchToProps = dispatch => ({
    getFacilitiesAction: (page, limit) => dispatch(getFacilitiesAction(page, limit)),
    saveRoomTypeAction: (body, callback) => dispatch(saveRoomTypeAction(body, callback)),
    updateRoomTypeAction: (id, body, callback) => dispatch(updateRoomTypeAction(id, body, callback)),
    roomTypesDataAction: (data) => dispatch(roomTypesDataAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeCUModal)