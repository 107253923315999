import { DELETE_STAFF, CREATE_STAFF, UPDATE_STAFF, GET_STAFF, STAFF_DATA, UPLOAD_IMAGES_STAFF, DELETE_IMAGE_STAFF, GET_STAFF_BY_AGENCY_ID } from "./constants";

export const saveStaffAction = (body, callback) => ({
    type: CREATE_STAFF,
    body,
    callback
});

export const updateStaffAction = (id, body, callback) => ({
    type: UPDATE_STAFF,
    id,
    body,
    callback
});

export const deleteStaffAction = (id, callback) => ({
    type: DELETE_STAFF,
    id,
    callback
});
export const uploadImagesStaffAction = (id, body, callback) => ({
    type: UPLOAD_IMAGES_STAFF,
    id,
    body,
    callback
});

export const deleteImageStaffAction = (id, body, callback) => ({
    type: DELETE_IMAGE_STAFF,
    id,
    body,
    callback
});


export const getStaffAction = (page, limit) => ({
    type: GET_STAFF,
    page,
    limit
});


export const staffDataAction = (data) => ({
    type: STAFF_DATA,
    data
});
