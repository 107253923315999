// src/pages/content/tours/redux/reducer.js
import {
    TOURS_DATA,
    GET_TOUR_BY_NAME,
} from "./constants";

const initialState = {
    tours_data: null,
    tour_detail: false,
};

const tourReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_TOUR_BY_NAME:
            return {
                ...state,
                tour_detail: action.data,
            };
        case TOURS_DATA:
            return {
                ...state,
                tours_data: action.data,
            };
        default:
            return state;
    }
};

export default tourReducer;
