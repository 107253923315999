
import { call, put, all, takeLatest } from "redux-saga/effects";
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction } from "../../../redux/actions";
import { CREATE_POLICY, DELETE_POLICY, GET_POLICIES, UPDATE_POLICY, } from "./constants";
import { createPolicy, deletePolicy, getPolicies, updatePolicy } from "../../../../services/services";
import { policiesDataAction } from "./actions";


function* createPolicySaga({ body, callback }) {
    try {
        const response = yield call(createPolicy, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* updatePolicySaga({ id, body, callback }) {
    try {
        const response = yield call(updatePolicy, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deletePolicySaga({ id, callback }) {
    try {
        const response = yield call(deletePolicy, id);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(id);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getPoliciesSaga({ page, limit }) {
    try {
        const response = yield call(getPolicies, page, limit);
        if (response.status === 200) {
            yield put(policiesDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(CREATE_POLICY, createPolicySaga),
    takeLatest(UPDATE_POLICY, updatePolicySaga),
    takeLatest(DELETE_POLICY, deletePolicySaga),
    takeLatest(GET_POLICIES, getPoliciesSaga),
]);