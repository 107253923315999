
import { call, put, all, takeLatest } from "redux-saga/effects";
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction } from "../../redux/actions";
import { CREATE_CATEGORY, DELETE_CATEGORY, DELETE_IMAGE_CATEGORY, GET_CATEGORIES, UPDATE_CATEGORY, UPLOAD_IMAGES_CATEGORY } from "./constants";
import { createCategory, deleteCategory, getCategories, imageDeleteCategory, imagesUploadCategory, updateCategory } from "../../../services/services";
import { categoriesDataAction } from "./actions";

function* uploadImagesCategorySaga({ id, body, callback }) {
    try {
        const response = yield call(imagesUploadCategory, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            debugger
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteImageCategorySaga({ id, body, callback }) {
    try {
        const response = yield call(imageDeleteCategory, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.message);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* createCategorySaga({ body, callback }) {
    try {
        const response = yield call(createCategory, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* updateCategorySaga({ id, body, callback }) {
    try {
        const response = yield call(updateCategory, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteCategorySaga({ id, callback }) {
    try {
        const response = yield call(deleteCategory, id);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(id);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getCategoriesSaga({ page, limit }) {
    try {
        const response = yield call(getCategories, page, limit);
        if (response.status === 200) {
            yield put(categoriesDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error?.response?.message ?? error.response?.data?.errors ?? error }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(CREATE_CATEGORY, createCategorySaga),
    takeLatest(UPDATE_CATEGORY, updateCategorySaga),
    takeLatest(UPLOAD_IMAGES_CATEGORY, uploadImagesCategorySaga),
    takeLatest(DELETE_IMAGE_CATEGORY, deleteImageCategorySaga),
    takeLatest(DELETE_CATEGORY, deleteCategorySaga),
    takeLatest(GET_CATEGORIES, getCategoriesSaga),
]);