
import { call, put, all, takeLatest } from "redux-saga/effects";
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction } from "../../redux/actions";
import { CREATE_TOUR, DELETE_IMAGE_TOUR, DELETE_TOUR, GET_TOURS, GET_TOURS_BY_AGENCY, GET_TOUR_BY_NAME, UPDATE_TOUR, UPLOAD_IMAGES_TOUR } from "./constants";
import { createTour, deleteTour, getTourByName, getTours, getToursByAgency, imageDeleteTour, imagesUploadTour, updateTour } from "../../../services/services";
import { toursDataAction } from "./actions";


function* uploadImagesTourSaga({ id, body, callback }) {
    try {
        const response = yield call(imagesUploadTour, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            debugger
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteImageTourSaga({ id, body, callback }) {
    try {
        const response = yield call(imageDeleteTour, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.message);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* createTourSaga({ body, callback }) {
    try {
        const response = yield call(createTour, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* updateTourSaga({ id, body, callback }) {
    try {
        const response = yield call(updateTour, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteTourSaga({ id, callback }) {
    try {
        const response = yield call(deleteTour, id);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(id);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getTourByNameSaga({ name, callback }) {
    try {
        const response = yield call(getTourByName, name);
        if (response.status === 200) {
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getToursSaga({ page, limit }) {
    try {
        const response = yield call(getTours, page, limit);
        if (response.status === 200) {
            yield put(toursDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getToursByAgencySaga({ queryParams }) {
    try {
        const response = yield call(getToursByAgency, queryParams);
        if (response.status === 200) {
            yield put(toursDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        console.log(error)
        yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(CREATE_TOUR, createTourSaga),
    takeLatest(UPDATE_TOUR, updateTourSaga),
    takeLatest(DELETE_TOUR, deleteTourSaga),
    takeLatest(UPLOAD_IMAGES_TOUR, uploadImagesTourSaga),
    takeLatest(DELETE_IMAGE_TOUR, deleteImageTourSaga),
    takeLatest(GET_TOURS, getToursSaga),
    takeLatest(GET_TOURS_BY_AGENCY, getToursByAgencySaga),
    takeLatest(GET_TOUR_BY_NAME, getTourByNameSaga),
]);