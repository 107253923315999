import { DELETE_USER, CREATE_USER, UPDATE_USER, GET_USERS, USERS_DATA, UPLOAD_IMAGES_USER, DELETE_IMAGE_USER } from "./constants";

export const saveUserAction = (body, callback) => ({
    type: CREATE_USER,
    body,
    callback
});

export const updateUserAction = (id, body, callback) => ({
    type: UPDATE_USER,
    id,
    body,
    callback
});

export const deleteUserAction = (id, callback) => ({
    type: DELETE_USER,
    id,
    callback
});
export const uploadImagesUserAction = (id, body, callback) => ({
    type: UPLOAD_IMAGES_USER,
    id,
    body,
    callback
});

export const deleteImageUserAction = (id, body, callback) => ({
    type: DELETE_IMAGE_USER,
    id,
    body,
    callback
});


export const getUsersAction = (page, limit) => ({
    type: GET_USERS,
    page,
    limit
});

export const usersDataAction = (data) => ({
    type: USERS_DATA,
    data
});
