import {
    ROOM_TYPES_DATA,
    GET_ROOM_TYPE_BY_NAME,
} from "./constants";

const initialState = {
    roomTypes_data: null,
    roomType_detail: false,
};

const roomTypeReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ROOM_TYPE_BY_NAME:
            return {
                ...state,
                roomType_detail: action.data,
            };
        case ROOM_TYPES_DATA:
            return {
                ...state,
                roomTypes_data: action.data,
            };
        default:
            return state;
    }
};

export default roomTypeReducer;
