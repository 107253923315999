import { DELETE_FACILITY, CREATE_FACILITY, UPDATE_FACILITY, GET_FACILITIES, FACILITIES_DATA } from "./constants";

export const saveFacilityAction = (body, callback) => ({
    type: CREATE_FACILITY,
    body,
    callback
});

export const updateFacilityAction = (id, body, callback) => ({
    type: UPDATE_FACILITY,
    id,
    body,
    callback
});

export const deleteFacilityAction = (id, callback) => ({
    type: DELETE_FACILITY,
    id,
    callback
});

export const getFacilitiesAction = (page, limit) => ({
    type: GET_FACILITIES,
    page,
    limit
});


export const facilitiesDataAction = (data) => ({
    type: FACILITIES_DATA,
    data
});