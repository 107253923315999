import { DELETE_ROLE, CREATE_ROLE, UPDATE_ROLE, GET_ROLES, ROLES_DATA, } from "./constants";

export const saveRoleAction = (body, callback) => ({
    type: CREATE_ROLE,
    body,
    callback
});

export const updateRoleAction = (id, body, callback) => ({
    type: UPDATE_ROLE,
    id,
    body,
    callback
});

export const deleteRoleAction = (id, callback) => ({
    type: DELETE_ROLE,
    id,
    callback
});

export const getRolesAction = (page, limit) => ({
    type: GET_ROLES,
    page,
    limit
});

export const rolesDataAction = (data) => ({
    type: ROLES_DATA,
    data
});
