import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import axios from 'axios';
import { Button, ButtonToolbar, Col, Container, Dropdown, Grid, IconButton, Pagination, Popover, Row, Table, Whisper, useToaster, } from 'rsuite';
import { connect } from 'react-redux';
import CategoryCUModal from './CategoryCUModal';
import { categoriesDataAction, deleteCategoryAction, deleteImageCategoryAction, getCategoriesAction, uploadImagesCategoryAction } from "./redux/actions"
import Swal from 'sweetalert2';
import MoreIcon from '@rsuite/icons/legacy/More';
import { CustomToaster, ImageUploadModal, PermissionsWrapper, SwalDeleteConfirm, renderMenu } from '../../components/CustomComponents';
import { hasPermission } from '../../utils/helpers';
import { CATEGORY_TYPES } from '../../utils/constants';

const ActionCell = ({ rowData, dataKey, callback, ...props }) => {
  return (
    <Table.Cell {...props} className="link-group">
      <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu(rowData, callback, true, true, "category")}>
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
    </Table.Cell>
  );
};

const Categories = (props) => {

  const [open, setOpen] = useState({ thumbnail: false, images: false, edit: false, create: false });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState(false);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    if (hasPermission(props.user?.role?.permissions, "category view")) {
      props.getCategoriesAction(page, limit);
    }
  }, [page, limit])

  const editHandler = (data, type) => {
    if (data) {
      setEditData(data);
      setOpen(prev => ({ ...prev, [type]: true }));
    }
  }

  const deleteHandler = (id) => {
    if (id) {
      SwalDeleteConfirm({
        onConfirm: (e) => {
          props.deleteCategoryAction(id, deleteCallback);
        },
        onCancel: (e) => { debugger }
      })
    }
  }

  const deleteCallback = useCallback((id) => {
    if (id) {
      let filteredList = props.categories_data.data?.filter(item => item.id !== id);
      props.categoriesDataAction({ ...props.categories_data, data: filteredList });
    }
  }, []);

  const actionHandler = useCallback((eventKey, rowData) => {
    switch (eventKey) {
      case 1:
        editHandler(rowData, "images")
        break;
      case 2:
        editHandler(rowData, "thumbnail")
        break;
      case 3:
        editHandler(rowData, "edit")
        break;
      case 4:
        deleteHandler(rowData.id)
        break;
      default:
        break;
    }

  }, [])

  return (
    <PermissionsWrapper permission="category view">
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
          <h2>Kategoriler</h2>
          {hasPermission(props.user?.role?.permissions, "category create") &&
            <Button appearance="primary" onClick={() => setOpen(prev => ({ ...prev, create: true }))} >
              Kategori Oluştur
            </Button>
          }
        </div>
        <div style={{ height: 500, marginTop: '30px' }}>
          <Table data={props.categories_data?.data || []} bordered rowHeight={60} fillHeight>

            <Table.Column width={80} align="center" fixed>
              <Table.HeaderCell>Logo</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <img src={rowData?.thumbnail ?? "https://cdn.pixabay.com/photo/2023/02/18/11/00/icon-7797704_1280.png"} style={{ width: '40px', height: '40px', objectFit: "cover", borderRadius: '10px' }} />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={200} flexGrow={2}>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.Cell dataKey="title" />
            </Table.Column>

            <Table.Column width={100} flexGrow={1}>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <span>{CATEGORY_TYPES?.find(type => type.value === rowData.type)?.label || ""}</span>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={100} fixed>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <ActionCell dataKey="id" callback={actionHandler} />
            </Table.Column>
          </Table>
        </div>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager']}
            total={props.categories_data?.total}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
        {open.create && hasPermission(props.user?.role?.permissions, "category create") ? <CategoryCUModal setOpen={setOpen} open={open.create} type={"create"} /> : null}

        {open.edit ? <CategoryCUModal setOpen={setOpen} open={open.edit} editData={editData} type={"edit"} setEditData={setEditData} /> : null}

        {open.thumbnail ? (
          <ImageUploadModal open={open.thumbnail} setOpen={setOpen} type={"thumbnail"} rowData={editData}
            uploadFunc={props.uploadImagesCategoryAction} deleteFunc={props.deleteImageCategoryAction}
            updateRowDataFunc={props.categoriesDataAction} tableList={props.categories_data} loading={props.common_requesting}
            setEditData={setEditData}
          />
        ) : null}

        {open.images ? (
          <ImageUploadModal open={open.images} setOpen={setOpen} type={"images"}
            rowData={editData}
            uploadFunc={props.uploadImagesCategoryAction}
            deleteFunc={props.deleteImageCategoryAction}
            updateRowDataFunc={props.categoriesDataAction} tableList={props.categories_data}
            loading={props.common_requesting}
            setEditData={setEditData}
          />
        ) : null}

      </div>
    </PermissionsWrapper>
  );
};
const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
  categories_data: state.categoryReducer.categories_data,
  common_error: state.commonReducer.common_error,
  user: state.commonReducer.user,
});

const mapDispatchToProps = dispatch => ({
  getCategoriesAction: (page, limit) => dispatch(getCategoriesAction(page, limit)),
  deleteCategoryAction: (id, callback) => dispatch(deleteCategoryAction(id, callback)),
  uploadImagesCategoryAction: (id, body, callback) => dispatch(uploadImagesCategoryAction(id, body, callback)),
  deleteImageCategoryAction: (id, body, callback) => dispatch(deleteImageCategoryAction(id, body, callback)),
  categoriesDataAction: (data) => dispatch(categoriesDataAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
