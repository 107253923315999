

export const GENDERS = ["Erkek", "Kadın"];
export const ROLES = [{ value: 2, name: "Kullanıcı" }, { value: 1, name: "Admin" }, { value: 3, name: "Agenta" }];
export const CURRENCIES = [
    { title: "United States Dollar ($)", value: "USD" },
    { title: "Euro (€)", value: "EUR" },
    { title: "British Pound (£)", value: "GBP" },
    { title: "Turkish Lira (₺)", value: "TRY" }
];
export const CATEGORY_TYPES = [
    { label: "Tur", value: "tours" }, { label: "Otel", value: "hotels" },
    { label: "Transfer", value: "transfers" }, { label: "Eğitim", value: "education" }
];

export const BANNER_SHAPES = [
    { label: "Dot", value: "dot" }, { label: "Bar", value: "bar" }
]

export const PERM_GUARD_NAMES = [
    { label: "Admin", value: "admin" }, { label: "Staff", value: "staff" }
]

export const POLICY_TYPES = [
    { label: "İptal", value: "cancellation" }, { label: "Ön ödeme", value: "prepayment" }
]

export const GBP = '\u00A3'; // GBP (£)
export const EURO = '\u20AC';  // Euro (€)
export const TL = '\u20BA'; // Turkish Lira (₺)
export const USD = '\u0024'; // US Dollar ($)
