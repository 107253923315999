import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux';
import { permissionsDataAction, savePermissionAction, updatePermissionAction } from './redux/actions';
import { Button, Col, Form, Grid, Input, Modal, SelectPicker, Tooltip, Whisper } from 'rsuite';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from '../../../components/CustomComponents';
import { PERM_GUARD_NAMES } from '../../../utils/constants';

const PermissionCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;

    const {
        handleSubmit,
        control, reset, watch, setValue, getValues,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        if (editData) {
            setValue("name", editData.name);
        }
    }, [editData])

    const onSubmit = data => {
        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('guard_name', props.user?.role?.id === 1 && props.user?.role?.title === "Admin" ? data.guard_name : "staff");

        if (editData) {
            props.updatePermissionAction(editData.id, formData, callback)
        } else {
            props.savePermissionAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.permissions_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.permissions_data.data, data];
            }
            props.permissionsDataAction({ ...props.permissions_data, data: updatedTableList, total: props.permissions_data.total + 1 });
            resetFields();
        }
    }, [])

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset();
        setOpen(prev => ({ ...prev, [type]: false }))
    }

    return (
        <Modal backdrop={"static"} size={"sm"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Olanak (Tesis) Ekle</Modal.Title>
            </Modal.Header>
            <Form fluid onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid fluid className='tour_form'>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            {/* name */}
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Olanak (Tesis) Ismi"}
                                        required
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input value={field?.value} onChange={field?.onChange} />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{ required: "Gerekli" }}
                            />
                        </Col>
                        {(props.user?.role?.id === 1 && props.user?.role?.title === "Admin") ?
                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                {/* guard_name */}
                                <Controller
                                    name="guard_name"
                                    control={control}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <CustomInput
                                            title={"Gardiyan Adı"}
                                            required
                                            error={errors[field?.name]?.message}
                                            children={
                                                <SelectPicker
                                                    data={PERM_GUARD_NAMES.map(item => ({ label: item.label, value: item.value }))}
                                                    value={field?.value}
                                                    onChange={field?.onChange}
                                                />
                                            }
                                        />
                                    )}
                                    rules={{ required: "Gerekli" }}
                                />
                            </Col>
                            : null}
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary" loading={props.common_requesting}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    user: state.commonReducer.user,
    permissions_data: state.permissionReducer.permissions_data
});

const mapDispatchToProps = dispatch => ({
    savePermissionAction: (body, callback) => dispatch(savePermissionAction(body, callback)),
    updatePermissionAction: (id, body, callback) => dispatch(updatePermissionAction(id, body, callback)),
    permissionsDataAction: (data) => dispatch(permissionsDataAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionCUModal)