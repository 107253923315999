import { DELETE_POLICY, CREATE_POLICY, UPDATE_POLICY, GET_POLICIES, POLICIES_DATA } from "./constants";

export const savePolicyAction = (body, callback) => ({
    type: CREATE_POLICY,
    body,
    callback
});

export const updatePolicyAction = (id, body, callback) => ({
    type: UPDATE_POLICY,
    id,
    body,
    callback
});

export const deletePolicyAction = (id, callback) => ({
    type: DELETE_POLICY,
    id,
    callback
});

export const getPoliciesAction = (page, limit) => ({
    type: GET_POLICIES,
    page,
    limit
});


export const policiesDataAction = (data) => ({
    type: POLICIES_DATA,
    data
});