import React, { useCallback, useEffect, useState } from 'react'
import { Accordion, Button, ButtonGroup, ButtonToolbar, CheckPicker, Col, DatePicker, Form, Grid, IconButton, Input, Modal, Row, SelectPicker } from 'rsuite';
import CloseIcon from '@rsuite/icons/Close';
import { FaAngleDoubleUp } from "react-icons/fa";
import { FaAngleDoubleDown } from "react-icons/fa";
import { BsFillInfoSquareFill } from "react-icons/bs";
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { VscUngroupByRefType } from "react-icons/vsc";
import { IoIosPricetags } from "react-icons/io";
import { getRoomTypesAction, roomTypesDataAction } from '../definitions/roomType/redux/actions';
import { getPoliciesAction } from '../definitions/policies/redux/actions';
import { CustomHeader, CustomInput, DynamicallyTag } from '../../components/CustomComponents';
import { disablePastDates } from '../../utils/helpers';
import { CURRENCIES } from '../../utils/constants';
import { roomsDataAction, saveRoomAction, updateRoomAction } from './redux/actions';

const RoomCUModal = (props) => {
    const { open, setOpen, selectedHotel, editData, setEditData, type } = props;
    const {
        handleSubmit,
        control, reset, watch, setValue, getValues,
        formState: { errors }
    } = useForm({
        defaultValues: {
            room_prices: [
                {
                    base_price: 0,
                    currency: 'USD',
                    policies: [],
                    additional_charges: {
                        total_price: 0,
                        currency: 'USD',
                        charges: [],
                    },
                    availability_start: new Date(new Date().setDate(new Date().getDate() + 1)),
                    availability_end: new Date(new Date().setDate(new Date().getDate() + 2)),
                },
            ],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'room_prices',
    });


    const [openRoomTypeModal, setOpenRoomTypeModal] = useState({ create: false });

    const [roomTypeItems, setRoomTypeItems] = useState([]);
    const roomTypesUpdateData = () => {
        setRoomTypeItems(props.roomTypes_data?.data?.map(item => ({ label: item.name, value: item.id })) ?? []);
    };

    const [policiesItems, setPoliciesItems] = useState([]);
    const policiesUpdateData = () => {
        setPoliciesItems(props.policies_data?.data?.map(item => ({ label: item.title, value: item.id })) ?? []);
    };

    useEffect(() => {
        props.getPoliciesAction(1, 500);
        props.getRoomTypesAction(1, 500);
    }, [])

    useEffect(() => {
        if (editData) {
            const transformedData = {
                room_type_id: editData.room_type_id || '',
                room_number: editData.room_number || '',
                is_hide: editData.is_hide || 0,
                isHighlighted: editData.isHighlighted || 0,
                room_prices: editData.roomPrices?.map(price => ({
                    base_price: price.base_price || 0,
                    currency: price.currency || 'USD',
                    policies: price.policies?.map(p => p.id) || [],
                    additional_charges: price.additional_charges
                        ? JSON.parse(price.additional_charges)
                        : {
                            total_price: 0,
                            currency: 'USD',
                            charges: [],
                        },
                    availability_start: price.availability_start
                        ? new Date(price.availability_start)
                        : null,
                    availability_end: price.availability_end
                        ? new Date(price.availability_end)
                        : null,
                })) || [],
            };
            reset(transformedData);
        }
    }, [editData])

    const onSubmit = data => {
        const formData = new FormData();
        formData.append("hotel_id", selectedHotel.id);

        Object.entries(data).forEach(([key, value]) => {
            if (key === "room_prices") {
                const tempPrices = value.map(price => ({
                    ...price,
                    additional_charges: JSON.stringify(price.additional_charges),
                }));
                formData.append(key, JSON.stringify(tempPrices))
            } else {
                formData.append(key, value)
            }
        })
        if (editData) {
            props.updateRoomAction(editData.id, formData, callback)
        } else {
            props.saveRoomAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.rooms_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.rooms_data?.data, data];
            }
            props.roomsDataAction({ ...props.rooms_data, data: updatedTableList, total: props.rooms_data?.total + 1 });
            resetFields();
        }
    }, [])

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset();
        setOpen(type)
    }

    const addRoomPricesHandler = () => {
        append({
            base_price: 0,
            currency: 'USD',
            policies: [],
            additional_charges: {
                total_price: 0,
                currency: 'USD',
                charges: [],
            },
            availability_start: '',
            availability_end: '',
        })
    }

    return (
        <>
            <Modal backdrop={"static"} size={"lg"} open={open} onClose={() => resetFields()}>
                <Modal.Header>
                    <Modal.Title>Oda Ekle</Modal.Title>
                </Modal.Header>
                <Form fluid onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <Grid fluid className='tour_form'>
                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Accordion bordered>
                                    <Accordion.Panel header={<CustomHeader Icon={<VscUngroupByRefType />} title={"Oda tipi"} />} defaultExpanded onClick={(event) => event.preventDefault()}>
                                        <Row>
                                            {/* room_type_id */}
                                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <Controller
                                                    name="room_type_id"
                                                    control={control}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Olanlardan Seç"}
                                                            error={errors[field?.name]?.message}
                                                            required
                                                            newBtnTitle={"Yeni Oda Tipi"}
                                                            newBtnAction={() => setOpenRoomTypeModal(prev => ({ ...prev, create: true }))}
                                                            children={
                                                                <SelectPicker
                                                                    data={roomTypeItems}
                                                                    value={field?.value}
                                                                    onChange={field?.onChange}
                                                                    onOpen={roomTypesUpdateData}
                                                                />
                                                            }
                                                        />
                                                    )}
                                                    rules={{ required: "Gerekli" }}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion.Panel>
                                    <Accordion.Panel header={<CustomHeader Icon={<BsFillInfoSquareFill />} title={"Oda Bilgileri"} />} onClick={(event) => event.preventDefault()}>
                                        <Row>
                                            {/* room_number */}
                                            <Col xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
                                                <Controller
                                                    name="room_number"
                                                    control={control}
                                                    defaultValue={0}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Oda Numarası"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <Input type='number' value={field?.value} onChange={field?.onChange} />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            {/* isHighlighted */}
                                            <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                                                <Controller
                                                    name="isHighlighted"
                                                    control={control}
                                                    defaultValue={0}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Öne Çıkanlardan?"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <ButtonToolbar>
                                                                    <ButtonGroup>
                                                                        <Button style={{ background: field.value === 1 ? '#2c792f' : "#c5c5c5" }} onClick={() => field?.onChange(1)} appearance={"primary"} endIcon={<FaAngleDoubleUp />}>Evet</Button>
                                                                        <Button style={{ background: field.value === 0 ? '#eb3e3e' : "#c5c5c5" }} onClick={() => field?.onChange(0)} appearance={"primary"} endIcon={<FaAngleDoubleDown />}>Hayır</Button>
                                                                    </ButtonGroup>
                                                                </ButtonToolbar>
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion.Panel>
                                    <Accordion.Panel header={<CustomHeader Icon={<IoIosPricetags />} title={"Fiyatlandırma"} />} onClick={(event) => event.preventDefault()}>
                                        <Row>
                                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <Button appearance="primary" onClick={() => addRoomPricesHandler()}>
                                                    Fiyat Ekle
                                                </Button>
                                            </Col>
                                            {fields.map((field, index) => (
                                                <div key={index} className='program_item'>
                                                    <h5 className='program_number'>{`Fiyat sırası: ${index + 1}`}</h5>
                                                    <Row>
                                                        {/* base_price */}
                                                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                            <Controller
                                                                name={`room_prices[${index}].base_price`}
                                                                control={control}
                                                                defaultValue={""}
                                                                render={({ field }) => (
                                                                    <CustomInput
                                                                        title={"Taban Fiyat"}
                                                                        required
                                                                        error={errors.room_prices?.[index]?.base_price?.message}
                                                                        children={
                                                                            <Input type='number' value={field.value} onChange={field.onChange} />
                                                                        }
                                                                    />
                                                                )}
                                                                rules={{ required: "Taban Fiyat gerekli." }}
                                                            />
                                                        </Col>
                                                        {/* currency */}
                                                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                            <Controller
                                                                name={`room_prices[${index}].currency`}
                                                                control={control}
                                                                defaultValue={"USD"}
                                                                render={({ field }) => (
                                                                    <CustomInput
                                                                        title={"Para Birimi"}
                                                                        error={errors.room_prices?.[index]?.currency?.message}
                                                                        required
                                                                        children={
                                                                            <SelectPicker
                                                                                data={CURRENCIES.map(item => ({ label: item.title, value: item.value }))}
                                                                                value={field.value} onChange={field.onChange}
                                                                                cleanable={false}
                                                                            />
                                                                        }
                                                                    />
                                                                )}
                                                                rules={{ required: "Para Birimi seçmek zorunludur." }}
                                                            />
                                                        </Col>
                                                        {/* availability_start */}
                                                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                            <Controller
                                                                name={`room_prices[${index}].availability_start`}
                                                                control={control}
                                                                defaultValue={new Date(new Date().setDate(new Date().getDate() + 1))}
                                                                render={({ field }) => (
                                                                    <CustomInput
                                                                        title={"Müsait Başlangıç Tarihi"}
                                                                        error={errors.room_prices?.[index]?.availability_start?.message}
                                                                        children={
                                                                            <DatePicker
                                                                                value={field?.value}
                                                                                onChange={field.onChange}
                                                                                shouldDisableDate={disablePastDates}
                                                                                format="yyyy-MM-dd HH:mm"
                                                                                style={{ width: '100%' }}
                                                                            />
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </Col>
                                                        {/* availability_end */}
                                                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                            <Controller
                                                                name={`room_prices[${index}].availability_end`}
                                                                control={control}
                                                                defaultValue={new Date(new Date().setDate(new Date().getDate() + 2))}
                                                                render={({ field }) => (
                                                                    <CustomInput
                                                                        title={"Müsait Bitiş Tarihi"}
                                                                        error={errors.room_prices?.[index]?.availability_end?.message}
                                                                        children={
                                                                            <DatePicker
                                                                                value={field?.value}
                                                                                onChange={field.onChange}
                                                                                shouldDisableDate={disablePastDates}
                                                                                format="yyyy-MM-dd HH:mm"
                                                                                style={{ width: '100%' }}
                                                                            />
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </Col>
                                                        {/* policies */}
                                                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                                            <Controller
                                                                name={`room_prices[${index}].policies`}
                                                                control={control}
                                                                defaultValue={[]}
                                                                render={({ field }) => (
                                                                    <CustomInput
                                                                        title={"Politikalar"}
                                                                        error={errors.room_prices?.[index]?.policies?.message}
                                                                        children={
                                                                            <CheckPicker
                                                                                data={policiesItems}
                                                                                value={field?.value}
                                                                                onChange={field?.onChange}
                                                                                onOpen={policiesUpdateData}
                                                                            />
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </Col>
                                                        {/* additional_charges */}
                                                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className='date_price_item'>
                                                            <h5 className='program_number'>Taban Fiyata Ek Masraflar</h5>
                                                            {/* total_price */}
                                                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                                <Controller
                                                                    name={`room_prices[${index}].additional_charges.total_price`}
                                                                    control={control}
                                                                    defaultValue={0}
                                                                    render={({ field }) => (
                                                                        <CustomInput
                                                                            title={"Toplam Fiyat"}
                                                                            error={errors.room_prices?.[index]?.additional_charges?.total_price?.message}
                                                                            children={
                                                                                <Input type='number' value={field.value} onChange={field.onChange} />
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </Col>
                                                            {/* currency */}
                                                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                                <Controller
                                                                    name={`room_prices[${index}].additional_charges.currency`}
                                                                    control={control}
                                                                    defaultValue={"USD"}
                                                                    render={({ field }) => (
                                                                        <CustomInput
                                                                            title={"Para Birimi"}
                                                                            error={errors.room_prices?.[index]?.additional_charges?.currency?.message}
                                                                            required
                                                                            children={
                                                                                <SelectPicker
                                                                                    data={CURRENCIES.map(item => ({ label: item.title, value: item.value }))}
                                                                                    value={field.value} onChange={field.onChange}
                                                                                    cleanable={false}
                                                                                />
                                                                            }
                                                                        />
                                                                    )}
                                                                    rules={{ required: "Para Birimi seçmek zorunludur." }}
                                                                />
                                                            </Col>
                                                            {/* charges */}
                                                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                                                <Controller
                                                                    name={`room_prices[${index}].additional_charges.charges`}
                                                                    control={control}
                                                                    defaultValue={[]}
                                                                    render={({ field }) => (
                                                                        <CustomInput
                                                                            title={"Masraflar"}
                                                                            error={errors.room_prices?.[index]?.additional_charges?.charges?.message}
                                                                            children={
                                                                                <div className='dynamically_tag_wrapper' style={{ background: '#fff' }}>
                                                                                    <DynamicallyTag tags={field?.value} setTags={field?.onChange} />
                                                                                </div>
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <IconButton color="red" appearance="primary" size="xs" className='close-btn' circle icon={<CloseIcon />} onClick={() => remove(index)} />
                                                </div>
                                            ))}

                                        </Row>
                                    </Accordion.Panel>
                                </Accordion>

                            </Col>

                        </Grid>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" appearance="primary" loading={props.common_requesting}>
                            Kaydet
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    rooms_data: state.roomReducer.rooms_data,
    roomTypes_data: state.roomTypeReducer.roomTypes_data,
    policies_data: state.policyReducer.policies_data,
});

const mapDispatchToProps = dispatch => ({
    getPoliciesAction: (page, limit) => dispatch(getPoliciesAction(page, limit)),
    getRoomTypesAction: (page, limit) => dispatch(getRoomTypesAction(page, limit)),
    roomsDataAction: (data) => dispatch(roomsDataAction(data)),
    roomTypesDataAction: (data) => dispatch(roomTypesDataAction(data)),
    saveRoomAction: (body, callback) => dispatch(saveRoomAction(body, callback)),
    updateRoomAction: (id, body, callback) => dispatch(updateRoomAction(id, body, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomCUModal)