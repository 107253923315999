import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux';
import { policiesDataAction, savePolicyAction, updatePolicyAction } from './redux/actions';
import { Button, Col, Form, Grid, Input, Modal, SelectPicker, Tooltip, Whisper } from 'rsuite';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from '../../../components/CustomComponents';
import { POLICY_TYPES } from '../../../utils/constants';

const PolicyCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;

    const {
        handleSubmit,
        control, reset, watch, setValue, getValues,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        if (editData) {
            Object.entries(editData).forEach(([key, value]) => {
                setValue(key, value);
            })
        }
    }, [editData])

    const onSubmit = data => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        })

        if (editData) {
            props.updatePolicyAction(editData.id, formData, callback)
        } else {
            props.savePolicyAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.policies_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.policies_data.data, data];
            }
            props.policiesDataAction({ ...props.policies_data, data: updatedTableList, total: props.policies_data.total + 1 });
            resetFields();
        }
    }, [])

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset();
        setOpen(prev => ({ ...prev, [type]: false }))
    }

    return (
        <Modal backdrop={"static"} size={"sm"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Politika Ekle</Modal.Title>
            </Modal.Header>
            <Form fluid onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid fluid className='tour_form'>
                        {/* type */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Controller
                                name="type"
                                control={control}
                                defaultValue={"cancellation"}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Politika Tipi"}
                                        required
                                        error={errors[field?.name]?.message}
                                        children={
                                            <SelectPicker
                                                data={POLICY_TYPES.map(item => ({ label: item.label, value: item.value }))}
                                                value={field?.value}
                                                onChange={field?.onChange}
                                                cleanable={false}
                                            />
                                        }
                                    />
                                )}
                                rules={{ required: "Gerekli" }}
                            />
                        </Col>
                        {/* title */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Başlık"}
                                        required
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input value={field?.value} onChange={field?.onChange} />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{ required: "Gerekli" }}
                            />
                        </Col>
                        {/* description */}
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Tanıtım"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input as={"textarea"} rows={4} value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                            />
                        </Col>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary" loading={props.common_requesting}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    policies_data: state.policyReducer.policies_data
});

const mapDispatchToProps = dispatch => ({
    savePolicyAction: (body, callback) => dispatch(savePolicyAction(body, callback)),
    updatePolicyAction: (id, body, callback) => dispatch(updatePolicyAction(id, body, callback)),
    policiesDataAction: (data) => dispatch(policiesDataAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PolicyCUModal)