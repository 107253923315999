import {
    HOTELS_DATA,
    GET_HOTEL_BY_NAME,
} from "./constants";

const initialState = {
    hotels_data: null,
    hotel_detail: false,
};

const hotelReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_HOTEL_BY_NAME:
            return {
                ...state,
                hotel_detail: action.data,
            };
        case HOTELS_DATA:
            return {
                ...state,
                hotels_data: action.data,
            };
        default:
            return state;
    }
};

export default hotelReducer;
