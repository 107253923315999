import { all } from 'redux-saga/effects';
import commonSaga from '../pages/redux/saga';
import tourSaga from '../pages/tours/redux/saga';
import categoorySaga from '../pages/category/redux/saga';
import subCategoorySaga from '../pages/subCategory/redux/saga';
import agencySaga from '../pages/agency/redux/saga';
import staffSaga from '../pages/staff/redux/saga';
import userSaga from '../pages/users/redux/saga';
import roleSaga from '../pages/role/redux/saga';
import hotelSaga from '../pages/hotels/redux/saga';
import roomSaga from '../pages/rooms/redux/saga';
import roomTypeSaga from '../pages/definitions/roomType/redux/saga';
import bannerSaga from '../pages/banners/redux/saga';
import facilitySaga from '../pages/definitions/facilities/redux/saga';
import permissionSaga from '../pages/definitions/permissions/redux/saga';
import policySaga from '../pages/definitions/policies/redux/saga';

export function* mainSaga() {
    //all application sagas here
    yield all([
        commonSaga,
        tourSaga,
        categoorySaga,
        subCategoorySaga,
        agencySaga,
        staffSaga,
        userSaga,
        roleSaga,
        hotelSaga,
        roomSaga,
        roomTypeSaga,
        bannerSaga,
        facilitySaga,
        permissionSaga,
        policySaga
    ]);
}
