import { DELETE_TOUR, CREATE_TOUR, UPDATE_TOUR, GET_TOURS, GET_TOUR_BY_NAME, TOURS_DATA, UPLOAD_IMAGES_TOUR, DELETE_IMAGE_TOUR, GET_TOURS_BY_AGENCY } from "./constants";

export const saveTourAction = (body, callback) => ({
    type: CREATE_TOUR,
    body,
    callback
});

export const updateTourAction = (id, body, callback) => ({
    type: UPDATE_TOUR,
    id,
    body,
    callback
});

export const deleteTourAction = (id, callback) => ({
    type: DELETE_TOUR,
    id,
    callback
});

export const getToursAction = (page, limit) => ({
    type: GET_TOURS,
    page,
    limit
});

export const getToursByAgencyAction = (queryParams) => ({
    type: GET_TOURS_BY_AGENCY,
    queryParams
});

export const getTourByNameAction = (name, callback) => ({
    type: GET_TOUR_BY_NAME,
    name,
    callback
});

export const toursDataAction = (data) => ({
    type: TOURS_DATA,
    data
});

export const uploadImagesTourAction = (id, body, callback) => ({
    type: UPLOAD_IMAGES_TOUR,
    id,
    body,
    callback
});

export const deleteImageTourAction = (id, body, callback) => ({
    type: DELETE_IMAGE_TOUR,
    id,
    body,
    callback
});
