import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux';
import { facilitiesDataAction, saveFacilityAction, updateFacilityAction } from './redux/actions';
import { Button, Col, Form, Grid, Input, Modal, Tooltip, Whisper } from 'rsuite';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from '../../../components/CustomComponents';

const FacilityCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;

    const {
        handleSubmit,
        control, reset, watch, setValue, getValues,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        if (editData) {
            setValue("name", editData.name);
        }
    }, [editData])

    const onSubmit = data => {
        const formData = new FormData();

        formData.append('name', data.name);

        if (editData) {
            props.updateFacilityAction(editData.id, formData, callback)
        } else {
            props.saveFacilityAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.facilities_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.facilities_data.data, data];
            }
            props.facilitiesDataAction({ ...props.facilities_data, data: updatedTableList, total: props.facilities_data.total + 1 });
            resetFields();
        }
    }, [])

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset();
        setOpen(prev => ({ ...prev, [type]: false }))
    }

    return (
        <Modal backdrop={"static"} size={"sm"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Olanak (Tesis) Ekle</Modal.Title>
            </Modal.Header>
            <Form fluid onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid fluid className='tour_form'>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            {/* name */}
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Olanak (Tesis) Ismi"}
                                        required
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input autoFocus value={field?.value} onChange={field?.onChange} />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{ required: "Gerekli" }}
                            />
                        </Col>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary" loading={props.common_requesting}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    facilities_data: state.facilityReducer.facilities_data
});

const mapDispatchToProps = dispatch => ({
    saveFacilityAction: (body, callback) => dispatch(saveFacilityAction(body, callback)),
    updateFacilityAction: (id, body, callback) => dispatch(updateFacilityAction(id, body, callback)),
    facilitiesDataAction: (data) => dispatch(facilitiesDataAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilityCUModal)