import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { Button, IconButton, Pagination, Popover, Row, Table, Whisper, useToaster, } from 'rsuite';
import { connect } from 'react-redux';
import PolicyCUModal from './PolicyCUModal';
import { policiesDataAction, deletePolicyAction, updatePolicyAction, getPoliciesAction } from "./redux/actions"
import MoreIcon from '@rsuite/icons/legacy/More';
import { PermissionsWrapper, SwalDeleteConfirm, renderMenu } from '../../../components/CustomComponents';
import { hasPermission } from '../../../utils/helpers';
import { POLICY_TYPES } from '../../../utils/constants';
import { MdFreeCancellation } from "react-icons/md";
import { MdOutlinePayment } from "react-icons/md";

const ActionCell = ({ rowData, dataKey, callback, ...props }) => {
  return (
    <Table.Cell {...props} className="link-group">
      <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu(rowData, callback, false, false, "policy")}>
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
    </Table.Cell>
  );
};

const Policies = (props) => {

  const [open, setOpen] = useState({ edit: false, create: false });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState(false);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    if (hasPermission(props.user?.role?.permissions, "policy view")) {
      props.getPoliciesAction(page, limit);
    }
  }, [page, limit])

  const editHandler = (data, type) => {
    if (data) {
      setEditData(data);
      setOpen(prev => ({ ...prev, [type]: true }));
    }
  }

  const deleteHandler = (id) => {
    if (id) {
      SwalDeleteConfirm({
        onConfirm: (e) => {
          props.deletePolicyAction(id, deleteCallback);
        },
        onCancel: (e) => { debugger }
      })
    }
  }

  const deleteCallback = useCallback((id) => {
    if (id) {
      let filteredList = props.policies_data.data?.filter(item => item.id !== id);
      props.policiesDataAction({ ...props.policies_data, data: filteredList });
    }
  }, []);

  const actionHandler = useCallback((eventKey, rowData) => {
    switch (eventKey) {
      case 3:
        editHandler(rowData, "edit")
        break;
      case 4:
        deleteHandler(rowData.id)
        break;
      default:
        break;
    }

  }, [])

  return (
    <PermissionsWrapper permission="policy view">
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
          <h2>Politikalar</h2>
          {hasPermission(props.user?.role?.permissions, "policy create") &&
            <Button appearance="primary" onClick={() => setOpen(prev => ({ ...prev, create: true }))} >
              Oluştur
            </Button>
          }
        </div>
        <div style={{ height: 500, marginTop: '30px' }}>
          <Table data={props.policies_data?.data || []} bordered rowHeight={60} fillHeight>

            <Table.Column width={200} flexGrow={2}>
              <Table.HeaderCell>Tipi</Table.HeaderCell>
              <Table.Cell>
                {rowData => {
                  const type = POLICY_TYPES.find(el => el.value === rowData.type);
                  return (
                    <div >
                      {(() => {
                        switch (rowData.type) {
                          case "cancellation":
                            return <MdFreeCancellation className='rs-icon' style={{ marginRight: '10px' }} />
                          case "prepayment":
                            return <MdOutlinePayment className='rs-icon' style={{ marginRight: '10px' }} />;
                          default:
                            return "";
                        }
                      })()}
                      <strong>{type?.label || ""}</strong>
                    </div>
                  )
                }}
              </Table.Cell>

            </Table.Column>

            <Table.Column width={200} flexGrow={1}>
              <Table.HeaderCell>Başlık</Table.HeaderCell>
              <Table.Cell dataKey="title" />
            </Table.Column>

            <Table.Column width={70} fixed>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <ActionCell dataKey="id" callback={actionHandler} />
            </Table.Column>
          </Table>
        </div>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager']}
            total={props.policies_data?.total}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
        {open.create && hasPermission(props.user?.role?.permissions, "policy create") ? <PolicyCUModal setOpen={setOpen} open={open.create} type={"create"} /> : null}

        {open.edit && hasPermission(props.user?.role?.permissions, "policy update") ? <PolicyCUModal setOpen={setOpen} open={open.edit} editData={editData} type={"edit"} setEditData={setEditData} /> : null}

      </div>
    </PermissionsWrapper>
  );
};
const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
  policies_data: state.policyReducer.policies_data,
  user: state.commonReducer.user,
});

const mapDispatchToProps = dispatch => ({
  getPoliciesAction: (page, limit) => dispatch(getPoliciesAction(page, limit)),
  deletePolicyAction: (id, callback) => dispatch(deletePolicyAction(id, callback)),
  policiesDataAction: (data) => dispatch(policiesDataAction(data)),
  updatePolicyAction: (id, body, callback) => dispatch(updatePolicyAction(id, body, callback))

});

export default connect(mapStateToProps, mapDispatchToProps)(Policies);
