import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Accordion, Button, ButtonGroup, ButtonToolbar, CheckPicker, Col, Form, Grid, IconButton, Input, InputNumber, Modal, Rate, Row, SelectPicker, Stack, Tooltip, Whisper } from 'rsuite';
import { CustomHeader, CustomInput, DynamicallyTag } from '../../components/CustomComponents';
import { connect } from 'react-redux';
import './hotels.scss';
import { saveHotelAction, hotelsDataAction, updateHotelAction } from './redux/actions';
import { getCategoriesAction } from '../category/redux/actions';
import { FaLocationArrow } from "react-icons/fa6";
import { BsFillInfoSquareFill } from "react-icons/bs";
import { FaAngleDoubleUp } from "react-icons/fa";
import { FaAngleDoubleDown } from "react-icons/fa";
import { FaSquarePhone } from "react-icons/fa6";
import { MdSpeakerNotes } from "react-icons/md";
import { getFacilitiesAction } from '../definitions/facilities/redux/actions';
import FacilityCUModal from '../definitions/facilities/FacilityCUModal';

const HotelCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;
    const {
        handleSubmit,
        control, reset, watch, setValue, getValues,
        formState: { errors }
    } = useForm();

    const [categoryItems, setCategoryItems] = useState(props.categories_data?.data?.map(item => ({ label: item.title, value: item.id, subCategories: item?.subCategories ?? [] })) ?? []);
    const categoryUpdateData = () => {
        if (categoryItems.length === 0) {
            setCategoryItems(props.categories_data?.data?.map(item => ({ label: item.title, value: item.id, subCategories: item?.subCategories ?? [] })) ?? []);
        }
    };
    const categoryId = watch('category_id');

    const [subCategoryItems, setSubCategoryItems] = useState([]);
    const subCategoryUpdateData = (id) => {
        if (categoryItems.length > 0) {
            let subCategories = categoryItems?.find(el => el.value === id)?.subCategories?.map(item => ({ label: item.title, value: item.id })) ?? []
            if (subCategories?.length > 0) {
                setSubCategoryItems(subCategories);
            }
        }
    };
    const [facilitiesItems, setFacilitiesItems] = useState([]);
    const facilitiesUpdateData = () => {
        setFacilitiesItems(props.facilities_data?.data?.map(item => ({ label: item.name, value: item.id })) ?? []);
    };

    const [rooms, setRooms] = useState([]);
    const [openFacilityModal, setOpenFacilityModal] = useState({ create: false });

    useEffect(() => {
        if (!Boolean(props.categories_data?.subCategories)) {
            props.getCategoriesAction(1, 50);
        }
        props.getFacilitiesAction(1, 500);
    }, []);

    useEffect(() => {
        if (editData) {
            Object.entries(editData).forEach(([key, value]) => {
                if (key === "rating" && Boolean(value)) {
                    setValue(key, parseFloat(value));
                } else if ((key === "latitude" || key === "longitude" || key === "postal_code") && Boolean(value)) {
                    setValue(key, parseFloat(value));
                } else if (key === "subCategories") {
                    setValue("sub_categories", value?.map(el => el.id) ?? []);
                } else if (key === "hotelFacilities") {
                    setValue("facilities", value?.map(el => el.id) ?? []);
                } else if ((key === "description" || key === "isHighlighted" || key === "check_in_time" || key === "check_out_time"
                    || key === "contact_number" || key === "email"
                    || key === "country" || key === "city" || key === "address" || key === "name") && Boolean(value)) {
                    setValue(key, value);
                }
                // else if (key === "rooms" && value?.length > 0) {
                //     setRooms(value.map(room => {
                //         let tempRoom = { id: room.id };
                //         Object.entries(room).forEach(([key, val], index) => {
                //             if ((key === "capacity" || key === "price") && val) {
                //                 tempRoom = { ...tempRoom, [key]: parseFloat(val) }
                //             } else
                //                 // if (key === "facilities" && val) {
                //                 //     tempRoom = { ...tempRoom, [key]: val.split("->") }
                //                 // } else
                //                 if (key === "cancellationPolicies" && val?.length > 0) {
                //                     tempRoom = { ...tempRoom, cancel_policies: [...val] }
                //                 } else if ((key === "title" || key === "room_type" || key === "isHighlighted" || key === "currency") && Boolean(val)) {
                //                     tempRoom = { ...tempRoom, [key]: val }
                //                 }
                //         })
                //         return tempRoom;
                //     }));
                // }
            })
            if (editData?.category_id) {
                subCategoryUpdateData(editData.category_id);
                setValue("category_id", parseInt(editData?.category_id));
            }
        }
    }, [editData])

    const onSubmit = data => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value], index) => {
            if (key === "rating") {
                formData.append(key, Boolean(value) ? parseFloat(value) : 0);
            } else if (key === "sub_categories" || key === "facilities") {
                value?.forEach(item => {
                    formData.append(`${key}[]`, parseInt(item));
                })
            } else {
                formData.append(key, value);
            }
        })

        if (editData) {
            props.updateHotelAction(editData.id, formData, callback)
        } else {
            props.saveHotelAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.hotels_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.hotels_data.data, data];
            }
            props.hotelsDataAction({ ...props.hotels_data, data: updatedTableList, total: props.hotels_data.total + 1 });
            resetFields();
        }
    }, [])

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset();
        setOpen(prev => ({ ...prev, [type]: false }))
    }
    return (
        <>
            <Modal backdrop={"static"} size={"lg"} open={open} onClose={() => resetFields()}>
                <Modal.Header>
                    <Modal.Title>Otel Ekle</Modal.Title>
                </Modal.Header>
                <Form fluid onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <Grid fluid className='tour_form'>
                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Accordion bordered>
                                    <Accordion.Panel header={<CustomHeader Icon={<BsFillInfoSquareFill />} title={"Genel Bilgiler"} />} defaultExpanded onClick={(event) => event.preventDefault()}>
                                        <Row>
                                            {/* category_id */}
                                            <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                                <Controller
                                                    name="category_id"
                                                    control={control}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Kategori"}
                                                            required
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <SelectPicker
                                                                    data={categoryItems}
                                                                    value={field?.value}
                                                                    onChange={field?.onChange}
                                                                    onSearch={categoryUpdateData}
                                                                    onOpen={categoryUpdateData}
                                                                    style={{ width: 300 }}
                                                                />
                                                            }
                                                        />
                                                    )}
                                                    rules={{ required: "Gerekli" }}
                                                />
                                            </Col>
                                            {/* sub_categories */}
                                            <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                                <Controller
                                                    name="sub_categories"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Alt Kategori"}
                                                            error={errors[field?.name]?.message}
                                                            required
                                                            children={
                                                                <CheckPicker
                                                                    data={subCategoryItems}
                                                                    value={field?.value}
                                                                    onChange={field?.onChange}
                                                                    onOpen={() => subCategoryUpdateData(categoryId)}
                                                                />
                                                            }
                                                        />
                                                    )}
                                                    rules={{ required: "Gerekli" }}
                                                />
                                            </Col>
                                            {/* isHighlighted */}
                                            <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                                <Controller
                                                    name="isHighlighted"
                                                    control={control}
                                                    defaultValue={0}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Öne Çıkanlardan?"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <ButtonToolbar>
                                                                    <ButtonGroup>
                                                                        <Button style={{ background: field.value === 1 ? '#2c792f' : "#c5c5c5" }} onClick={() => field?.onChange(1)} appearance={"primary"} endIcon={<FaAngleDoubleUp />}>Evet</Button>
                                                                        <Button style={{ background: field.value === 0 ? '#eb3e3e' : "#c5c5c5" }} onClick={() => field?.onChange(0)} appearance={"primary"} endIcon={<FaAngleDoubleDown />}>Hayır</Button>
                                                                    </ButtonGroup>
                                                                </ButtonToolbar>
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            {/* name */}
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                                                <Controller
                                                    name="name"
                                                    control={control}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Otel Ismi"}
                                                            required
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                                    <Input value={field?.value} onChange={field?.onChange} />
                                                                </Whisper>
                                                            }
                                                        />
                                                    )}
                                                    rules={{ required: "Gerekli" }}
                                                />
                                            </Col>
                                            {/* rating */}
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                                                <Controller
                                                    name="rating"
                                                    control={control}
                                                    defaultValue={0}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Oran"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <Rate color='yellow' allowHalf {...field} />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion.Panel>
                                    <Accordion.Panel header={<CustomHeader Icon={<FaLocationArrow />} title={"Konum"} />} onClick={(event) => event.preventDefault()}>
                                        <Row>
                                            {/* country */}
                                            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                                                <Controller
                                                    name="country"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Ülke"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <Input value={field?.value} onChange={field?.onChange} />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            {/* city */}
                                            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                                                <Controller
                                                    name="city"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Şehir"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <Input value={field?.value} onChange={field?.onChange} />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            {/* address */}
                                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <Controller
                                                    name="address"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Adres"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <Input value={field?.value} onChange={field?.onChange} />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            {/* latitude */}
                                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                                <Controller
                                                    name="latitude"
                                                    control={control}
                                                    defaultValue={0}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Latitude"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <Input type='number' value={field?.value} onChange={field?.onChange} />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            {/* longitude */}
                                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                                <Controller
                                                    name="longitude"
                                                    control={control}
                                                    defaultValue={0}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Longitude"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <Input type='number' value={field?.value} onChange={field?.onChange} />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            {/* postal_code */}
                                            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                                                <Controller
                                                    name="postal_code"
                                                    control={control}
                                                    defaultValue={0}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Postal Code"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <Input type='number' value={field?.value} onChange={field?.onChange} />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion.Panel>
                                    <Accordion.Panel header={<CustomHeader Icon={<MdSpeakerNotes />} title={"Tesisler ve Diğer Notlar"} />} onClick={(event) => event.preventDefault()}>
                                        <Row>
                                            {/* facilities */}
                                            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                                                <Controller
                                                    name="facilities"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Otel Olanakları"}
                                                            error={errors[field?.name]?.message}
                                                            newBtnTitle={"Yeni"}
                                                            newBtnAction={() => setOpenFacilityModal(prev => ({ ...prev, create: true }))}
                                                            children={
                                                                <CheckPicker
                                                                    data={facilitiesItems}
                                                                    value={field?.value}
                                                                    onChange={field?.onChange}
                                                                    onOpen={facilitiesUpdateData}
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            {/* check_in_time */}
                                            <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                                                <Controller
                                                    name="check_in_time"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Giriş saatı"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <Input value={field?.value} onChange={field?.onChange} />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            {/* check_out_time */}
                                            <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                                                <Controller
                                                    name="check_out_time"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Çıkış saatı"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <Input value={field?.value} onChange={field?.onChange} />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            {/* description */}
                                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <Controller
                                                    name="description"
                                                    control={control}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            title={"Tanım"}
                                                            error={errors[field?.name]?.message}
                                                            children={
                                                                <Input value={field?.value} onChange={field?.onChange} as={"textarea"} rows={3} />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion.Panel>
                                    <Accordion.Panel header={<CustomHeader Icon={<FaSquarePhone />} title={"İletişim Bilgiler"} />} onClick={(event) => event.preventDefault()}>
                                        {/* contact_number */}
                                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                                            <Controller
                                                name="contact_number"
                                                control={control}
                                                defaultValue={""}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"Tel Numara"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <Input type='number' maxLength={16} value={field?.value} onChange={field?.onChange} />
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        {/* email */}
                                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                                            <Controller
                                                name="email"
                                                control={control}
                                                defaultValue={''}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        title={"E-posta"}
                                                        error={errors[field?.name]?.message}
                                                        children={
                                                            <Input value={field?.value} onChange={field?.onChange} />
                                                        }
                                                    />
                                                )}
                                                rules={{
                                                    pattern: {
                                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                        message: "Geçerli bir e-posta adresi girin"
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Accordion.Panel>
                                </Accordion>
                            </Col>



                        </Grid>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" appearance="primary" loading={props.common_requesting}>
                            Kaydet
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <FacilityCUModal setOpen={setOpenFacilityModal} open={openFacilityModal.create} type={"create"} />
        </>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    hotels_data: state.hotelReducer.hotels_data,
    categories_data: state.categoryReducer.categories_data,
    sub_categories_data: state.subCategoryReducer.sub_categories_data,
    facilities_data: state.facilityReducer.facilities_data,
});

const mapDispatchToProps = dispatch => ({
    getFacilitiesAction: (page, limit) => dispatch(getFacilitiesAction(page, limit)),
    getCategoriesAction: (page, limit) => dispatch(getCategoriesAction(page, limit)),
    hotelsDataAction: (data) => dispatch(hotelsDataAction(data)),
    saveHotelAction: (body, callback) => dispatch(saveHotelAction(body, callback)),
    updateHotelAction: (id, body, callback) => dispatch(updateHotelAction(id, body, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(HotelCUModal)