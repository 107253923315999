import React, { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Col, Form, Grid, InlineEdit, Input, Loader, Row, Stack } from 'rsuite';
import './agency-profile-case.scss';
import { CustomInput } from '../../components/CustomComponents';
import { getAgencyByIdAction, updateAgencyAction } from '../agency/redux/actions';

const TextArea = React.forwardRef((props, ref) => {
    return <Input as="textarea" ref={ref} {...props} />;
});

const Field = ({ label, as: Component, value, onSave, onChange, defaultValue, ...rest }) => {
    return (
        <Stack className='agency-field'>
            <label style={{ width: 120, display: 'inline-block', color: 'var(--rs-text-secondary)' }}>
                {label}
            </label>
            <InlineEdit stateOnBlur="cancel" onSave={onSave} placeholder="Click to edit ..." defaultValue={defaultValue} value={value} onChange={onChange}>
                <Component {...rest} />
            </InlineEdit>
        </Stack>
    );
};

const AgencyProfile = (props) => {

    const {
        handleSubmit,
        control, reset, setValue, getValues,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        if (data && props.user?.agency) {
            const formData = new FormData();

            Object.entries(data).forEach(([key, value]) => {
                formData.append(key, value);
            })

            props.updateAgencyAction(props.user?.agency.id, formData, () => { });
        }
    }

    useEffect(() => {
        if (props.user?.agency) {
            props.getAgencyByIdAction(props.user?.agency?.id, getAgencyCallback);
        }
    }, [props.user])

    const getAgencyCallback = useCallback((data) => {
        if (data) {
            setValue("title", data?.title ?? "")
            setValue("contact_number", data?.contact_number ?? "")
            setValue("email", data?.email ?? "")
            setValue("address", data?.address ?? "")
            setValue("description", data?.description ?? "")
        }
    }, [])

    return (
        <div>
            <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Stack direction="column" alignItems="flex-start" spacing={10}>
                        <Form fluid onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name={"title"}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                    <CustomInput
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Field label="Başlık" as={Input} value={field.value} onChange={field.onChange} onSave={handleSubmit(onSubmit)} />
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name={"email"}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                    <CustomInput
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Field label="E-posta" as={Input} value={field.value} onChange={field.onChange} onSave={handleSubmit(onSubmit)} />
                                        }
                                    />
                                )}
                            />
                            {props.user?.agency?.contact_number ?
                                <Controller
                                    name={"contact_number"}
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <CustomInput
                                            error={errors[field?.name]?.message}
                                            children={
                                                <Field label="Iletişim Numara" as={Input} value={field.value} onChange={field.onChange} onSave={handleSubmit(onSubmit)} />
                                            }
                                        />
                                    )}
                                />
                                : null}
                            {props.user?.agency?.address ?
                                <Controller
                                    name={"address"}
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <CustomInput
                                            error={errors[field?.name]?.message}
                                            children={
                                                <Field label="Adres" as={Input} value={field.value} onChange={field.onChange} onSave={handleSubmit(onSubmit)} />
                                            }
                                        />
                                    )}
                                />
                                : null}
                            {props.user?.agency?.description ?
                                <Controller
                                    name={"description"}
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <CustomInput
                                            error={errors[field?.name]?.message}
                                            children={
                                                <Field label="Tanıtım" as={TextArea} row={5} value={field.value} onChange={field.onChange} onSave={handleSubmit(onSubmit)} />
                                            }
                                        />
                                    )}
                                />
                                : null}
                            {/* <Field
                                label="Date of Birth"
                                as={DatePicker}
                                defaultValue={new Date('1988-08-08')}
                                format="MMMM dd, yyyy"
                            />
                            <Field
                                label="Skills"
                                as={TagPicker}
                                data={skills}
                                defaultValue={['React', 'TypeScript', 'Node.js']}
                            />
                            <Field label="Skill Level" as={Slider} defaultValue={50} /> */}
                        </Form>
                        {props.common_requesting && <Loader backdrop content="Loading..." vertical />}
                    </Stack>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <img alt='profile' src={props.user?.agency?.thumbnail ?? ""} style={{ borderRadius: '10px', maxWidth: '100%', maxHeight: '400px', objectFit: 'cover' }} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}
const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    user: state.commonReducer.user,
    agency_data: state.agencyReducer.agency_data
});

const mapDispatchToProps = dispatch => ({
    updateAgencyAction: (id, body, callback) => dispatch(updateAgencyAction(id, body, callback)),
    getAgencyByIdAction: (id, callback) => dispatch(getAgencyByIdAction(id, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(AgencyProfile)