import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';


//private routes for application
const PrivateRoutes = () => {
    const isLogged = useSelector(state => state.commonReducer.isLogged);
    const twj = Cookies.get("twj");
    return (
        (isLogged && twj) ? <Outlet /> : <Navigate to="/login" />
    )
}

export default PrivateRoutes