import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { Button, IconButton, Pagination, Table, Whisper, } from 'rsuite';
import { connect } from 'react-redux';
import BannerCUModal from './BannerCUModal';
import { bannersDataAction, deleteBannerAction, deleteImageBannerAction, getBannersAction, updateBannerAction, uploadImagesBannerAction } from "./redux/actions"
import Swal from 'sweetalert2';
import MoreIcon from '@rsuite/icons/legacy/More';
import { CustomToaster, ImageUploadModal, PermissionsWrapper, SwalConfirm, SwalDeleteConfirm, renderMenu } from '../../components/CustomComponents';
import { hasPermission } from '../../utils/helpers';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoIosCheckmarkCircle } from "react-icons/io";

const ActionCell = ({ rowData, dataKey, callback, ...props }) => {
  return (
    <Table.Cell {...props} className="link-group">
      <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu(rowData, callback, true, false, "banner")}>
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
    </Table.Cell>
  );
};

const Banners = (props) => {

  const [open, setOpen] = useState({ images: false, edit: false, create: false });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState(false);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    if (hasPermission(props.user?.role?.permissions, "banner view")) {
      props.getBannersAction(page, limit);
    }
  }, [page, limit])

  const editHandler = (data, type) => {
    if (data) {
      setEditData(data);
      setOpen(prev => ({ ...prev, [type]: true }));
    }
  }

  const deleteHandler = (id) => {
    if (id) {
      SwalDeleteConfirm({
        onConfirm: (e) => {
          props.deleteBannerAction(id, deleteCallback);
        },
        onCancel: (e) => { debugger }
      })
    }
  }

  const deleteCallback = useCallback((id) => {
    if (id) {
      let filteredList = props.banners_data.data?.filter(item => item.id !== id);
      props.bannersDataAction({ ...props.banners_data, data: filteredList });
    }
  }, []);

  const actionHandler = useCallback((eventKey, rowData) => {
    switch (eventKey) {
      case 1:
        editHandler(rowData, "images")
        break;
      case 3:
        editHandler(rowData, "edit")
        break;
      case 4:
        deleteHandler(rowData.id)
        break;
      default:
        break;
    }

  }, [])

  const activeHandler = (rowData) => {
    if (rowData && hasPermission(props.user?.role?.permissions, "banner update")) {
      SwalConfirm({
        text: Boolean(rowData.is_active) ? "Bu Afiş pasif olacak" : "Bu Afiş aktif olacak",
        onConfirm: (e) => {
          const formData = new FormData();
          formData.append("is_active", Boolean(rowData.is_active) ? 0 : 1);

          props.updateBannerAction(rowData.id, formData, hideCallback)
        },
        onCancel: (e) => { debugger }
      })
    }
  }

  const hideCallback = (data) => {
    if (data) {
      let updatedTableList = [];
      updatedTableList = props.banners_data.data?.map(item => {
        if (item.id === data.id) {
          return { ...data, is_active: parseInt(data.is_active) };
        }
        return item;
      })
      props.bannersDataAction({ ...props.banners_data, data: [...updatedTableList] });
    }
  };

  return (
    <PermissionsWrapper permission="banner view">
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
          <h2>Afişler</h2>
          {hasPermission(props.user?.role?.permissions, "banner create") &&
            <Button appearance="primary" onClick={() => setOpen(prev => ({ ...prev, create: true }))} >
              Afiş Oluştur
            </Button>
          }
        </div>
        <div style={{ height: 500, marginTop: '30px' }}>
          <Table data={props.banners_data?.data || []} bordered rowHeight={60} fillHeight>

            <Table.Column width={200} flexGrow={2}>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.Cell dataKey="title" />
            </Table.Column>

            <Table.Column width={100} flexGrow={1}>
              <Table.HeaderCell>Link To</Table.HeaderCell>
              <Table.Cell dataKey="link_to" />
            </Table.Column>

            <Table.Column width={80} fixed>
              <Table.HeaderCell>Aktif</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <IconButton style={{ fontSize: 16 }} appearance="subtle" icon={Boolean(rowData.is_active) ? <IoIosCheckmarkCircle color="green" /> : <IoIosCheckmarkCircleOutline />} onClick={() => activeHandler(rowData)} />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={100} fixed>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <ActionCell dataKey="id" callback={actionHandler} />
            </Table.Column>
          </Table>
        </div>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager']}
            total={props.banners_data?.total}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
        {open.create && hasPermission(props.user?.role?.permissions, "banner create") ? <BannerCUModal setOpen={setOpen} open={open.create} type={"create"} /> : null}

        {open.edit ? <BannerCUModal setOpen={setOpen} open={open.edit} editData={editData} type={"edit"} setEditData={setEditData} /> : null}

        {open.images ? (
          <ImageUploadModal open={open.images} setOpen={setOpen} type={"images"}
            rowData={editData}
            uploadFunc={props.uploadImagesBannerAction}
            deleteFunc={props.deleteImageBannerAction}
            updateRowDataFunc={props.bannersDataAction} tableList={props.banners_data}
            loading={props.common_requesting}
            setEditData={setEditData}
          />
        ) : null}

      </div>
    </PermissionsWrapper>
  );
};
const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
  banners_data: state.bannerReducer.banners_data,
  common_error: state.commonReducer.common_error,
  user: state.commonReducer.user,
});

const mapDispatchToProps = dispatch => ({
  getBannersAction: (page, limit) => dispatch(getBannersAction(page, limit)),
  deleteBannerAction: (id, callback) => dispatch(deleteBannerAction(id, callback)),
  uploadImagesBannerAction: (id, body, callback) => dispatch(uploadImagesBannerAction(id, body, callback)),
  deleteImageBannerAction: (id, body, callback) => dispatch(deleteImageBannerAction(id, body, callback)),
  bannersDataAction: (data) => dispatch(bannersDataAction(data)),
  updateBannerAction: (id, body, callback) => dispatch(updateBannerAction(id, body, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
