import { DELETE_CATEGORY, CREATE_CATEGORY, UPDATE_CATEGORY, GET_CATEGORIES, CATEGORIES_DATA, UPLOAD_IMAGES_CATEGORY, DELETE_IMAGE_CATEGORY } from "./constants";

export const saveCategoryAction = (body, callback) => ({
    type: CREATE_CATEGORY,
    body,
    callback
});

export const updateCategoryAction = (id, body, callback) => ({
    type: UPDATE_CATEGORY,
    id,
    body,
    callback
});

export const deleteCategoryAction = (id, callback) => ({
    type: DELETE_CATEGORY,
    id,
    callback
});
export const uploadImagesCategoryAction = (id, body, callback) => ({
    type: UPLOAD_IMAGES_CATEGORY,
    id,
    body,
    callback
});

export const deleteImageCategoryAction = (id, body, callback) => ({
    type: DELETE_IMAGE_CATEGORY,
    id,
    body,
    callback
});


export const getCategoriesAction = (page, limit) => ({
    type: GET_CATEGORIES,
    page,
    limit
});

export const categoriesDataAction = (data) => ({
    type: CATEGORIES_DATA,
    data
});
