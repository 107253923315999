import React, { useRef, useState, useEffect, useCallback } from 'react';
import { IconButton, Loader } from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import PlusIcon from '@rsuite/icons/Plus';
import CloseIcon from '@rsuite/icons/Close';
import { connect } from 'react-redux';
import { PermissionsWrapper, SwalDeleteConfirm } from '../../components/CustomComponents';
import { deleteRoomAction, getRoomsByHotelIdAction, roomsDataAction } from './redux/actions';
import RoomCUModal from './RoomCUModal';
import './rooms-case.scss';
import { hasPermission } from '../../utils/helpers';

const RoomList = (props) => {

    const { rowData } = props;

    const [open, setOpen] = useState({ edit: false, create: false });
    const [roomEditData, setRoomEditData] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        if (rowData) {
            props.getRoomsByHotelIdAction({ hotel_id: rowData.id, pagination: false }, callback);
        }
    }, [rowData])

    const callback = useCallback((data) => {
        if (data) {
        }
    }, []);

    const editHandler = (roomData, type) => {
        if (roomData && hasPermission(props.user?.role?.permissions, "room update")) {
            setRoomEditData(roomData);
            setOpen(prev => ({ ...prev, [type]: true }));
        }
    }


    const deleteHandler = (id) => {
        if (id && hasPermission(props.user?.role?.permissions, "room delete")) {
            SwalDeleteConfirm({
                onConfirm: (e) => {
                    props.deleteRoomAction(id, deleteCallback);
                },
                onCancel: (e) => { debugger }
            })
        }
    }

    const deleteCallback = useCallback((id) => {
        if (id) {
            let filteredList = props.rooms_data.data?.filter(item => item.id !== id);
            props.roomsDataAction({ ...props.rooms_data, data: filteredList });
        }
    }, [props.rooms_data]);

    // Handle mouse dragging
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX) * 1; // The multiplier controls the scroll speed
        containerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };
    
    return (
        <PermissionsWrapper permission="room view">
            <div className="hotel_rooms"
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp} // Ensures dragging stops when the mouse leaves the container
            >
                {props.rooms_data?.data?.map((val, key) => (
                    <div
                        key={key}
                        className="hotel_rooms_item"
                    >
                        <img
                            loading='lazy'
                            src={val?.roomType?.thumbnail}
                            alt={`Room ${val?.room_number}`}
                            style={{ borderRadius: '10px', width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                        <div className="overlay">
                            <div className="button-group">
                                {hasPermission(props.user?.role?.permissions, "room update") ?
                                    <IconButton
                                        circle
                                        onClick={() => editHandler(val, "edit")}
                                        icon={<EditIcon />}
                                        aria-label={`Edit Room ${val?.room_number}`}
                                    />
                                    : null}
                                {hasPermission(props.user?.role?.permissions, "room delete") ?
                                    <IconButton
                                        circle
                                        onClick={() => deleteHandler(val.id)}
                                        icon={<CloseIcon />}
                                        aria-label={`Delete Room ${val?.room_number}`}
                                    />
                                    : null}
                            </div>
                        </div>
                    </div>
                ))}
                {props.common_requesting && <Loader backdrop content="loading..." />}
                {hasPermission(props.user?.role?.permissions, "room create") ?
                    <div>
                        <IconButton
                            className="tag-add-btn"
                            onClick={() => setOpen(prev => ({ ...prev, create: true }))}
                            icon={<PlusIcon />}
                            appearance="ghost"
                            size="md"
                            aria-label="Add Room"
                        />
                    </div>
                    : null}
            </div>
            {open.create && hasPermission(props.user?.role?.permissions, "room create") ? <RoomCUModal setOpen={setOpen} selectedHotel={rowData} open={open.create} type={"create"} /> : null}
            {open.edit && hasPermission(props.user?.role?.permissions, "room update") ? <RoomCUModal setOpen={setOpen} selectedHotel={rowData} open={open.edit} editData={roomEditData} type={"edit"} setEditData={setRoomEditData} /> : null}
        </PermissionsWrapper>
    );
};
const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    rooms_data: state.roomReducer.rooms_data,
    user: state.commonReducer.user
});

const mapDispatchToProps = dispatch => ({
    getRoomsByHotelIdAction: (queryParams, callback) => dispatch(getRoomsByHotelIdAction(queryParams, callback)),
    deleteRoomAction: (id, callback) => dispatch(deleteRoomAction(id, callback)),
    roomsDataAction: (data) => dispatch(roomsDataAction(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(RoomList);
