import { put, call, all, takeLatest } from 'redux-saga/effects';
import { LOGIN, LOGOUT } from './constants';
import Cookies from 'js-cookie';
import { login, logout } from '../../services/services';
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction, logoutSuccessAction, userDataAction } from './actions';

function* loginSaga({ body }) {
    try {
        const response = yield call(login, body);
        if (response.status === 200) {
            Cookies.set('twj', response.data.user.token, { expires: 1 });
            delete response.data.user.token
            yield put(userDataAction(response.data.user))
            window.location.replace("/agency-profile");
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* logoutSaga() {
    try {
        yield call(logout, Cookies.get("twj"));
    } catch (error) {
        if (error?.response?.status === 400) {
            // let errors = error.response?.data?.errors.map((errorObj) => Object.values(errorObj)[0]);
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(logoutSuccessAction());
        window.location.replace("/");
    }
}
export default all([
    takeLatest(LOGIN, loginSaga),
    takeLatest(LOGOUT, logoutSaga),
]);