import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { Button, IconButton, Pagination, Popover, Row, Table, Whisper, useToaster, } from 'rsuite';
import { connect } from 'react-redux';
import FacilityCUModal from './FacilityCUModal';
import { facilitiesDataAction, deleteFacilityAction, updateFacilityAction, getFacilitiesAction } from "./redux/actions"
import MoreIcon from '@rsuite/icons/legacy/More';
import { PermissionsWrapper, SwalDeleteConfirm, renderMenu } from '../../../components/CustomComponents';
import { hasPermission } from '../../../utils/helpers';

const ActionCell = ({ rowData, dataKey, callback, ...props }) => {
  return (
    <Table.Cell {...props} className="link-group">
      <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu(rowData, callback, false, false, "facility")}>
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
    </Table.Cell>
  );
};

const Facilities = (props) => {

  const [open, setOpen] = useState({ edit: false, create: false });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState(false);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    if (hasPermission(props.user?.role?.permissions, "facility view")) {
      props.getFacilitiesAction(page, limit);
    }
  }, [page, limit])

  const editHandler = (data, type) => {
    if (data) {
      setEditData(data);
      setOpen(prev => ({ ...prev, [type]: true }));
    }
  }

  const deleteHandler = (id) => {
    if (id) {
      SwalDeleteConfirm({
        onConfirm: (e) => {
          props.deleteFacilityAction(id, deleteCallback);
        },
        onCancel: (e) => { debugger }
      })
    }
  }

  const deleteCallback = useCallback((id) => {
    if (id) {
      let filteredList = props.facilities_data.data?.filter(item => item.id !== id);
      props.facilitiesDataAction({ ...props.facilities_data, data: filteredList });
    }
  }, []);

  const actionHandler = useCallback((eventKey, rowData) => {
    switch (eventKey) {
      case 3:
        editHandler(rowData, "edit")
        break;
      case 4:
        deleteHandler(rowData.id)
        break;
      default:
        break;
    }

  }, [])

  return (
    <PermissionsWrapper permission="facility view">
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
          <h2>Olanaklar (Tesisler)</h2>
          {hasPermission(props.user?.role?.permissions, "facility create") &&
            <Button appearance="primary" onClick={() => setOpen(prev => ({ ...prev, create: true }))} >
              Oluştur
            </Button>
          }
        </div>
        <div style={{ height: 500, marginTop: '30px' }}>
          <Table data={props.facilities_data?.data || []} bordered rowHeight={60} fillHeight>

            <Table.Column width={200} flexGrow={1}>
              <Table.HeaderCell>Isim</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>

            <Table.Column width={70} fixed>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <ActionCell dataKey="id" callback={actionHandler} />
            </Table.Column>
          </Table>
        </div>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager']}
            total={props.facilities_data?.total}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
        {open.create && hasPermission(props.user?.role?.permissions, "facility create") ? <FacilityCUModal setOpen={setOpen} open={open.create} type={"create"} /> : null}

        {open.edit && hasPermission(props.user?.role?.permissions, "facility update") ? <FacilityCUModal setOpen={setOpen} open={open.edit} editData={editData} type={"edit"} setEditData={setEditData} /> : null}

      </div>
    </PermissionsWrapper>
  );
};
const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
  facilities_data: state.facilityReducer.facilities_data,
  common_error: state.commonReducer.common_error,
  user: state.commonReducer.user,
});

const mapDispatchToProps = dispatch => ({
  getFacilitiesAction: (page, limit) => dispatch(getFacilitiesAction(page, limit)),
  deleteFacilityAction: (id, callback) => dispatch(deleteFacilityAction(id, callback)),
  facilitiesDataAction: (data) => dispatch(facilitiesDataAction(data)),
  updateFacilityAction: (id, body, callback) => dispatch(updateFacilityAction(id, body, callback))

});

export default connect(mapStateToProps, mapDispatchToProps)(Facilities);
