import {
    ROOMS_DATA,
    GET_ROOM_BY_NAME,
} from "./constants";

const initialState = {
    rooms_data: null,
    room_detail: false,
};

const roomReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ROOM_BY_NAME:
            return {
                ...state,
                room_detail: action.data,
            };
        case ROOMS_DATA:
            return {
                ...state,
                rooms_data: action.data,
            };
        default:
            return state;
    }
};

export default roomReducer;
